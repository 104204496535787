import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { SuccessSubscribeComponent } from 'src/app/components/success-subscribe/success-subscribe.component';

enum Subscription{
  Unsub = 'unsub',
  Canceled = 'canceled',
  Ended = 'ended',
  Active = 'active',
  Trialing = 'trialing'
}

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {

  new_subscriber:boolean = false;
  old_subscriber:boolean = false;
  active_subscriber:boolean = false;
  trial_subscriber:boolean = false;
  renewal_date:any;
  months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  spinner: boolean;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const today = new Date();
    let d = new Date(today.setMonth(today.getMonth()+1));
    this.renewal_date = d.getDate() + ' ' + this.months[d.getMonth()] + ' ' +d.getFullYear().toString().substr(-2);
    this.renewal_date = this.getFormattedDate(d);
    const subscrip = localStorage.getItem('subscription');
    if(subscrip === Subscription.Canceled || subscrip === Subscription.Ended){
      this.old_subscriber = true;
    }else if(subscrip === Subscription.Active){
      this.active_subscriber = true;
    }else if(subscrip === Subscription.Trialing){
      this.trial_subscriber = true;
    }else if(subscrip === Subscription.Unsub){
      this.new_subscriber = true;
    }else{
      this.new_subscriber = true;
    }
  }
  addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
}
  subscribe(){
    //subscribe the customer
    const body = {
      customer_id: sessionStorage.getItem('user_id'),
    };
    this.spinner = true;
    this.userService.addSubscription(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });              
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(SuccessSubscribeComponent, {
            autoFocus: false,
          });            
          const subscrip = localStorage.getItem('subscription');
          if(subscrip === Subscription.Canceled || subscrip === Subscription.Ended){
            this.old_subscriber = false;
            this.active_subscriber = true;
            localStorage.setItem('subscription',Subscription.Active);
          }else if(subscrip === Subscription.Unsub){
            this.new_subscriber = false;
            this.trial_subscriber = true;
            localStorage.setItem('subscription',Subscription.Trialing);
          }      
        }else{
          this.snackBar.open(
            res.message,
            'Dismiss',
            {
              duration: 4000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            }
          );                                
        }      
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );    
  }
  closeSubscribe(){
    this.dialog.closeAll();
  }
  getFormattedDate(date) {
    let d = date.getDate();
    let sup = '';
    switch (d) {
      case 1:
      case 21:
      case 31:
      sup =  "st";
      break;
      case 2:
      case 22:
      sup =  "nd";
      break;
      case 3:
      case 23:
      sup =  "rd";
      break;
      default: 
      sup =  "th";
      break;
    }
    let month = this.months.filter(i => i === this.months[date.getMonth()]);
    let formatted_date = month + ' ' + d + sup + ' ' + date.getFullYear();
    return formatted_date;
  }
}
