import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { LoginComponent } from 'src/app/login/login.component';
import { BookingService } from '../../../core/services/booking.service';

@Component({
  selector: 'app-manage-schedule',
  templateUrl: './manage-schedule.component.html',
  styleUrls: ['./manage-schedule.component.css'],
})
export class ManageScheduleComponent implements OnInit {
  toggle: boolean;
  spinner: boolean;
  notes: any;
  smallbagcount: any;
  largebagcount: any;
  timeArray: any;
  bookDate: any;

  bookTime = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bookingService: BookingService,
    public dialogRef: MatDialogRef<ManageScheduleComponent>,
    public dialog: MatDialog,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.notes = sessionStorage.getItem('notes');
    this.toggle = false;

    this.populateTime();

    this.filteredOptions = this.bookTime.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.timeArray.filter(
      (option: string) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  populateTime() {
    var x = 5;
    var times = [];
    var tt = 0;
    var ap = ['AM', 'PM'];

    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60);
      var mm = tt % 60;
      times[i] =
        ('0' + (hh % 12)).slice(-2) +
        ':' +
        ('0' + mm).slice(-2) +
        ap[Math.floor(hh / 12)];
      tt = tt + x;
    }

    this.timeArray = times;
  }

  bookNow() {
    if (this.isLoggedIn()) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const date = yyyy + '-' + mm + '-' + dd;
      const latitude = this.data.address.geometry.location.lat();
      const longitude = this.data.address.geometry.location.lng();
      const body = {
        mode: 'ridenow',
        customer_id: sessionStorage.getItem('user_id'),
        pick_up: this.data.address.formatted_address,
        pickup_lat: latitude.toString(),
        pickup_lon: longitude.toString(),
        drop_location: 'NoDrop',
        drop_lat: latitude.toString(),
        drop_lon: longitude.toString(),
        vehicle_id: '1',
        service_id: '1',
        pick_up_time: this.bookTime,
        customer_notes: this.notes,
        date: date,
        small_bag: this.smallbagcount,
        large_bag: this.largebagcount,
      };
      this.submit(body);
    } else {
      const book = 1;
      this.openLoginDialog(book);
    }
  }

  bookLater() {
    if (this.isLoggedIn()) {
      if (this.bookTime.value === null || this.bookTime.value === undefined) {
        this.spinner = false;
        this.snackBar.open(
          'Please insert the time for your pickup',
          'Dismiss',
          {
            duration: 5000,
            verticalPosition: 'top',
          }
        );
      } else {
        if (this.bookDate !== null && this.bookDate !== undefined) {
          const latitude = this.data.address.geometry.location.lat();
          const longitude = this.data.address.geometry.location.lng();
          const date = this.formatDate(this.bookDate);
          const body = {
            mode: 'ridelater',
            customer_id: sessionStorage.getItem('user_id'),
            pick_up: this.data.address.formatted_address,
            pickup_lat: latitude.toString(),
            pickup_lon: longitude.toString(),
            drop_location: 'NoDrop',
            drop_lat: latitude.toString(),
            drop_lon: longitude.toString(),
            vehicle_id: '1',
            service_id: '1',
            date: date,
            pick_up_time: this.bookTime.value,
            customer_notes: this.notes,
          };

          this.submit(body);
        } else {
          this.snackBar.open(
            'Please insert the date for your pickup',
            'Dismiss',
            {
              duration: 5000,
              verticalPosition: 'top',
            }
          );
        }
      }
    } else {
      const book = 2;
      this.openLoginDialog(book);
    }
  }

  toggleBookLater() {
    this.toggle = !this.toggle;
  }

  openLoginDialog(book) {
    const dialogRef = this.dialog.open(LoginComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if ((result = true)) {
        this.notes = sessionStorage.getItem('notes');
        if (book === 1) {
          this.bookNow();
        } else if (book === 2) {
          this.bookLater();
        }
      }
    });
  }

  isLoggedIn() {
    if ('user_id' in sessionStorage) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  updateNotes() {
    const body = {
      id: sessionStorage.getItem('user_id'),
      notes: this.notes,
    };
    this.userService.updateUser(body).subscribe(
      (res: any) => {},
      (error: any) => {
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          verticalPosition: 'top',
        });
      }
    );
  }

  submit(body: any) {
 
    this.spinner = true;
    console.log(body);
    this.bookingService.create(body).subscribe(
      (res: any) => {
        if (res.code === 500) {
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 5000,
            verticalPosition: 'top',
          });
        } else if (res.code === 200) {
          this.spinner = false;
          if(res.status){            
            this.dialogRef.close(true);
          }else{
            this.snackBar.open(res.message, 'Dismiss', {
              duration: 5000,
              verticalPosition: 'top',
            });
          }                   
        }
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          verticalPosition: 'top',
        });
      }
    );
  }
}
