<mat-dialog-content class="mat-dialog-content-card">
    <div class="container" id="card-page">
        <mat-card>
            <form [formGroup]="cardForm">
                <div class="form-wrap mt-3">                    
                    <div class="container d-flex h-50" *ngIf="!editCard">
                        <div class="row justify-content-center align-self-center">          
                            <img src="{{icon}}" class="img-fluid" alt="CC" style="max-width:50px;max-height:50px" /> <span style="align-self: center;"> {{c.ccnumber.value}} </span>
                            <button class="btn btn-primary ml-2"  color="primary" (click)="modifyCard()" style="max-height:40px">
                            Change
                            </button>                            
                        </div>   
                    </div>
                    <div class="form-inr" *ngIf="editCard">
                        <h4>Card Details</h4>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class='required'>Name on Card</label>
                                <input formControlName="ccname" class="form-control hpsNumberField"
                                    [ngClass]="{ 'is-invalid': submitted && c.ccname.errors }" />
                                <div *ngIf="submitted && c.ccname.errors" class="invalid-feedback">
                                    <div *ngIf="c.ccname.errors.required">Card holder name is required</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class='required'>Card Number</label>
                                <input type="tel" formControlName="ccnumber" class="form-control hpsNumberField"
                                    [ngClass]="{ 'is-invalid': submitted && c.ccnumber.errors }" maxlength="19"
                                    minlength="13" />
                                <div *ngIf="submitted && c.ccnumber.errors" class="invalid-feedback">
                                    <div *ngIf="c.ccnumber.errors.required">Card Number is required</div>
                                    <div *ngIf="c.ccnumber.errors?.maxlength || c.ccnumber.errors?.minlength">Card
                                        Number must be 13 to 19 digits long</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <label class='required'>Expiration Month</label>
                                <select formControlName="expirationMonth" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.expirationMonth.errors }">
                                    <option *ngFor="let month of months" [value]="month.value">
                                        {{month.viewValue}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && c.expirationMonth.errors" class="invalid-feedback">
                                    <div *ngIf="c.expirationMonth.errors.required">Expiration month is required</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label class='required'>Expiration Year</label>
                                <select formControlName="expirationYear" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.expirationYear.errors }">
                                    <option *ngFor="let year of years" [value]="year.value">
                                        {{year.viewValue}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && c.expirationYear.errors" class="invalid-feedback">
                                    <div *ngIf="c.expirationYear.errors.required">Expiration year is required</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label class='required'>Security Code</label>
                                <input type="tel" formControlName="cvv" class="form-control hpsNumberField"
                                    [ngClass]="{ 'is-invalid': submitted && c.cvv.errors }" maxlength="4"
                                    minlength="3" />
                                <div *ngIf="submitted && c.cvv.errors" class="invalid-feedback">
                                    <div *ngIf="c.cvv.errors.required">Security Code is required</div>
                                    <div *ngIf="c.cvv.errors?.maxlength || c.cvv.errors?.minlength">Security Code must
                                        be 3 to 4 digts long</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 class="mt-1"  *ngIf="editCard">Billing Information</h4>
                    <div class="form-inr" *ngIf="editCard">
                        <div class="row mt-3">
                            <div class="col-sm-6">
                                <label class='required'>Address line 1</label>
                                <input type="text" formControlName="addressOne" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.addressOne.errors }" maxlength="140"
                                    minlength="2" />
                                <div *ngIf="submitted && c.addressOne.errors" class="invalid-feedback">
                                    <div *ngIf="c.addressOne.errors.required">Billing Address 1 is required</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Address line 2</label>
                                <input type="text" formControlName="addressTwo" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.addressTwo.errors }" maxlength="140"
                                    minlength="2" />
                                <div *ngIf="submitted && c.addressTwo.errors" class="invalid-feedback">
                                    <div *ngIf="c.addressTwo.errors.required">Billing Address 2 is required</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class='required'>City</label>
                                <input type="text" formControlName="city" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.city.errors }" />
                                <div *ngIf="submitted && c.city.errors" class="invalid-feedback">
                                    <div *ngIf="c.city.errors.required">City is required</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class='required'>State</label>
                                <input type="text" formControlName="state" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.state.errors }" />
                                <div *ngIf="submitted && c.state.errors" class="invalid-feedback">
                                    <div *ngIf="c.state.errors.required">State is required</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class='required'>Postal/Zip</label>
                                <input type="text" formControlName="postal_code" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && c.postal_code.errors }" />
                                <div *ngIf="submitted && c.postal_code.errors" class="invalid-feedback">
                                    <div *ngIf="c.postal_code.errors.required">Postal/Zip Code is required</div>
                                </div>
                            </div>
                        </div>                       
                        <div class="modal-button">
                            <button class="btn btn-primary" color="primary" (click)="updateCard()">
                                Update Card
                            </button>
                            <button class="btn btn-primary ml-2" color="primary"  (click)="cancelCard()" style="max-height:40px">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</mat-dialog-content>
<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>