import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private routes: Router) {}

  canActivate(): boolean {
    if (sessionStorage.getItem('user_id') != null) {
      return true;
    } else {
      sessionStorage.clear();
      this.routes.navigate(['/']);
      return false;
    }
  }
}
