<div mat-dialog-content class="dialog-width">
<div class="card">    
  <img class="mx-auto d-block" src="../../../assets/confirm-booking.png" alt="confirmation" />
  <div class="card-body">    
    <p class="text-justify mt-1" style="color: #61aeef">
      You didt it. Sit back and relax. Your laundry is almost done. We will let you know as soon as a Laundry Pro has accepted the order.
    </p>
  </div>
  <!--
  <div class="card-footer">
    <button (click)="close()">Close</button>
  </div>
  -->
</div>
</div>  