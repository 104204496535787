import { Component, OnInit } from '@angular/core';
import {FormControl, Validators,FormGroup,FormBuilder, FormArray} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { NavbarComponent } from '../shared/navbar/navbar.component';


interface Month {
  value: string;
  viewValue: string;
}

interface Year {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  spinner: boolean = true;
  registerForm: any;
  submitted: boolean = false;

  name: any;
  email: any;
  password: any;
  confirmPassword: any;
  ccnumber: any;
  ccname: any;
  expirationMonth: any;
  expirationYear: any;
  cvv: any;
  trips: any;
  notes: any;
  PAT_NAME: any;
  editCard: boolean = false;
  icon: string;

  months: Month[] = [
    {value: '1', viewValue: 'January'},
    {value: '2', viewValue: 'February'},
    {value: '3', viewValue: 'March'},
    {value: '4', viewValue: 'April'},
    {value: '5', viewValue: 'May'},
    {value: '6', viewValue: 'June'},
    {value: '7', viewValue: 'July'},
    {value: '8', viewValue: 'August'},
    {value: '9', viewValue: 'September'},
    {value: '10', viewValue: 'October'},
    {value: '11', viewValue: 'November'},
    {value: '12', viewValue: 'December'}
  ];

  years: Year[] = [
    {value: '2021', viewValue: '2021'},
    {value: '2022', viewValue: '2022'},
    {value: '2023', viewValue: '2023'},
    {value: '2024', viewValue: '2024'},
    {value: '2025', viewValue: '2025'},
    {value: '2026', viewValue: '2026'},
    {value: '2027', viewValue: '2027'},
    {value: '2028', viewValue: '2028'},
    {value: '2029', viewValue: '2029'},
    {value: '2030', viewValue: '2030'},
    {value: '2031', viewValue: '2031'},
    {value: '2032', viewValue: '2032'},
    {value: '2033', viewValue: '2033'},
    {value: '2034', viewValue: '2034'},
    {value: '2035', viewValue: '2035'},
    {value: '2036', viewValue: '2036'},
    {value: '2037', viewValue: '2037'},
    {value: '2038', viewValue: '2038'},
    {value: '2039', viewValue: '2039'},
    {value: '2040', viewValue: '2040'},
    {value: '2041', viewValue: '2041'},
    {value: '2042', viewValue: '2042'},
    {value: '2043', viewValue: '2043'},
    {value: '2044', viewValue: '2044'},
    {value: '2045', viewValue: '2045'},
    {value: '2046', viewValue: '2046'},
    {value: '2047', viewValue: '2047'},
    {value: '2048', viewValue: '2048'},
    {value: '2049', viewValue: '2049'},
    {value: '2050', viewValue: '2050'},
    {value: '2051', viewValue: '2051'},
    {value: '2052', viewValue: '2052'},
  ];
  
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<RegisterComponent>,
    private snackBar: MatSnackBar,
    private router: Router,
    private formBuilder: FormBuilder,    
    public dialog: MatDialog
  ) {}

  get f() { return this.registerForm.controls; }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.maxLength(10) , Validators.minLength(8)]],
      postal_code: [''],
      address: [''],
      city: [''],
      password: ['', [Validators.required, Validators.maxLength(50) , Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(50) , Validators.minLength(8)]],
      notes: [''],
      agree:false,
    });

    this.spinner = false;
  }

  openCardDetails() {
    if (
      (/\d/.test(this.registerForm.value.password) ||
        /[a-zA-Z]/.test(this.registerForm.value.password)) === false
    ) {
      this.snackBar.open('Your password requires at least one number and one letter', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
    } else {
      if (this.registerForm.valid) {
        this.submitted = true;
      } else {        
        this.snackBar.open('Error: All fields are required.', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    }
  }
  onCheckboxChange(e) {
    /*
    if (e.target.checked) {
      this.cardForm.controls.city.setValue(this.registerForm.controls.city.value);
      this.cardForm.controls.postal_code.setValue(this.registerForm.controls.postal_code.value);
      this.cardForm.controls.addressOne.setValue(this.registerForm.controls.address.value);        
    } else {
      this.cardForm.controls.city.setValue('');
      this.cardForm.controls.postal_code.setValue('');
      this.cardForm.controls.addressOne.setValue('');        
    }
    */
  }
  register() {
    this.submitted = true;
    if(this.registerForm.value.password !== this.registerForm.value.confirmPassword){
      this.snackBar.open('Error: Password mismatch!', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if(!Number(this.registerForm.value.phone_number)){
      this.snackBar.open('Error: Invalid phone number!', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if(!this.registerForm.value.agree){
      this.snackBar.open('Error: You must agree to the terms, conditions, and Policy.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if (this.registerForm.valid) {
      this.spinner = true;
      const body = {
        name: this.registerForm.value.name,
        last_name: this.registerForm.value.last_name,
        phone_number: this.registerForm.value.phone_number,
        email: this.registerForm.value.email,
        password: this.registerForm.value.password,
        repeat_password: this.registerForm.value.confirmPassword,
        address: this.registerForm.value.address,
        city: this.registerForm.value.city,
        postal_code: this.registerForm.value.postal_code,
        notes: this.registerForm.value.notes,
        /*
        card_name: this.cardForm.value.ccname,
        card_number: this.cardForm.value.ccnumber,
        card_month_expiry: this.cardForm.value.expirationMonth,
        card_year_expiry: this.cardForm.value.expirationYear,
        card_cvv: this.cardForm.value.cvv,
        card_postal_code: this.cardForm.controls.postal_code.value,
        card_address1: this.cardForm.controls.addressOne.value,
        card_address2: this.cardForm.controls.addressTwo.value,
        card_city: this.cardForm.controls.city.value,
        card_state: this.cardForm.controls.state.value,
        */
      };
      this.authService.register(body).subscribe(
        (_res: any) => {          
          if(_res.status){
            this.snackBar.open(_res.message, 'Dismiss', {
              duration: 3000,
              panelClass: ['notif-success-green'],
              verticalPosition: 'top',
            });
            this.spinner = false;
            /*
            this.dialog.open(LoginComponent, {
              autoFocus: false,
            });
            */
            this.dialogRef.close();
            this.loginRegisteredUser();
          }else{
            this.spinner = false;          
            this.snackBar.open(_res.message, 'Dismiss', {
              duration: 3000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            });
            this.submitted = false;  
          }
        },
        (_error: any) => {
          this.spinner = false;          
          this.snackBar.open(_error.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
          this.submitted = false;
        }
      );
    } else {      
      this.snackBar.open('Error: All fields are required.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
    }
  }
  login(){
    this.spinner = false;
    this.dialog.open(LoginComponent, {
      autoFocus: false,
    });
    this.dialogRef.close();
  }
  loginRegisteredUser(){
    this.spinner = true;
    const body = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      type: 'customer',
    };
    this.authService.login(body).subscribe(
      (res: any) => {
        if(res.status){
          sessionStorage.setItem('user_id', res.customer.id);
          sessionStorage.setItem('customer', res.customer);
          localStorage.setItem('user_id', res.customer.id);
          localStorage.setItem('customer', res.customer);
          localStorage.setItem('subscription', res.subscription.original.data['subscription_status']);
          sessionStorage.setItem('notes', res.customer.notes ? res.customer.notes : '');
          //this.navbar.isLoggedIn();
          this.dialogRef.close(true);

          const subscrip = res.subscription.original.data['subscription_status'];
            this.router.navigate(['/plans']);            
        }else{
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });        
        }        
      },
      (_error: any) => {        
        this.spinner = false;
        this.snackBar.open(_error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });        
      }
    );
  }
}
