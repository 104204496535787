import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  public httpOptions: any;

  constructor(private http: HttpClient) {}

  create(body) {
    return this.http.post<any>(`${environment.apidirect}/booking/add`, body);
  }

  getAll(body) {
    return this.http.post<any>(`${environment.api}/bookings-all`, body);
  }
  getBookingDetail(body) {
    return this.http.post<any>(`${environment.apidirect}/booking/detail`, body);
  }  
  setupCall(body:any){
    return this.http.post<any>(`${environment.apidirect}/booking/call`, body);
  }
  sendMessage(body:any){
    return this.http.post<any>(`${environment.apidirect}/booking/sendMessage`, body);
  }
  cancelOrder(body:any){
    return this.http.post<any>(`${environment.apidirect}/booking/add`, body);
  }
  getPickupTimeFrames(body) {
    return this.http.post<any>(`${environment.apidirect}/booking/getPickupTimeFrames`, body);
  }  
  getServerTime(){
    return this.http.get<any>(`${environment.apidirect}/booking/getservertime`);
  }
  getAutoOrderSchedule(body){
    return this.http.post<any>(`${environment.apidirect}/customer/getAutoOrderSchedule`, body);
  }
  setupAutoOrder(body){
    return this.http.post<any>(`${environment.apidirect}/customer/setupAutoOrder`, body);
  }
  cancelAutoOrder(body){
    return this.http.post<any>(`${environment.apidirect}/customer/cancelAutoOrder`, body);
  }
  retryAutoOrder(body){
    return this.http.post<any>(`${environment.apidirect}/customer/scheduleAutoOrderRetry`, body);
  }
}