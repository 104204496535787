import { Component, OnInit,ViewChild} from '@angular/core';
import { BookingService } from 'src/app/core/services/booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { CommonService } from '../../core/services/common.service';
import {FormControl, FormGroup,FormBuilder} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SuccessMessageComponent } from 'src/app/components/success-message/success-message.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatRadioChange } from '@angular/material/radio';
import { CardComponent } from 'src/app/pages/card/card.component';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

declare var $: any; // ADD THIS
enum Subscription{
  Unsub = 'unsub',
  Canceled = 'canceled',
  Ended = 'ended',
  Active = 'active',
  Trialing = 'trialing',
  Plan = 'Pro Crastinator($9.99/month)',
  Basic = "Pay as you Go"
}
interface StripeSubscription{  
  status:boolean;
  plan:string;
  start: string;
  end: string;
  suspended: boolean;
}
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  orderForm: FormGroup;
  address:any;
  apartment:any='';
  small_bag:any=0;
  large_bag:any=0;
  delivery_charges:any=0;
  env_fee:any=0;
  sales_tax:any=0;
  sub_total:any=0;
  total:any=0;
  code: any='';
  coupon_discount:any=0;
  pro_discount:number=0;
  subscription:StripeSubscription = {end:'',start:'',plan:'',status:false,suspended:false};
  tip:any=0;
  toggle: boolean=false;
  spinner: boolean;
  timeArray: any;
  bookDate:any;
  //bookDate: new FormControl((new Date()).toISOString().substring(0, 10));
  
  bookTime = new FormControl();  
  filteredOptions: Observable<string[]>;
  delivery_instructions: any;
  latitude = '0.00';
  longitude = '0.00';
  formatted_address='';
  agree:boolean=false;
  dattimemodal:any;
  customer_balance:any;
  small_bag_amount:any;
  large_bag_amount:any;
  timeFrames:any = '1';  
  timeFrameSelected:any;  
  pickup_time_frames:any;
  hours:any;
  minutes:any;
  pickup_date:any;
  server_date_time:any;
  server_date:any;
  server_date_formatted:any;
  min_calendar_date:any;
  max_booking_date:any;
  server_time:any;
  weeklySelected:any="0";
  wbQuestion:boolean = false;
  wbOptions:boolean = false; 
  oneTimeservice:boolean = false; 
  serviceSelection:any;
  weeklySelection:any;
  useWalletBalance:boolean=false;
  largeBagRate:number=45;
  largeBagFee:number=1.58;
  smallBagRate:number=30;
  smallBagFee:number=1.05;
  salesTaxRate:number=8.25;
  picker_date:any;
  available_days:any;
  selectedDate:any;

  constructor(
    private bookingService: BookingService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    public dialog: MatDialog
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.orderForm.controls; }
  @ViewChild('scheduleLatermodal', {static: false}) private scheduleLatermodal: NgbModal;
  @ViewChild(CardComponent) private card: CardComponent;
  @ViewChild('input_date') private input_date: HTMLDataElement;

  ngOnInit(): void {
   this.orderForm = this.formBuilder.group({
      small_bag: [0],
      large_bag: [0],
      code:''
      });    
    this.wbOptions = false;
    this.wbQuestion = false;
    this.timeFrames = 0;
    $('[data-toggle="popover"]').popover();
   
    if(localStorage.getItem('address')){
      this.formatted_address = localStorage.getItem('address');
      this.address = this.formatted_address;
    }
    if(localStorage.getItem('lat')){
      this.latitude = localStorage.getItem('lat');
    }
    if(localStorage.getItem('lng')){
      this.longitude= localStorage.getItem('lng');
    }
    //this.bookDate = new Date();
    this.getCustomerBalance();
    this.getBagRates();
    this.getServerTime();    
    console.log(" input date: " + this.input_date);
  }
  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.timeArray.filter(
      (option: string) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  create() {
    if(this.address === undefined || this.address === null || this.address.geometry == null || this.address.geometry == undefined){
      this.snackBar.open('Enter your pickup location address, you must select an address from the location suggestions. For more details please use the delivery instructions box.', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });     
      return;
    }                 
    this.formatted_address = this.address.formatted_address;
    localStorage.setItem('address',this.address.formatted_address);
    localStorage.setItem('lat',this.address.geometry.location.lat());
    localStorage.setItem('lng',this.address.geometry.location.lng());    

    this.latitude = this.address.geometry.location.lat();
    this.longitude = this.address.geometry.location.lng();
    this.getPickupTimeFrames();
  }
  largeBagPlus(){
    this.large_bag++;
    this.updateCart();
  }
  largeBagMinus(){
    if(this.large_bag >0){
      this.large_bag--;
      this.updateCart();
    }
  }
  smallBagPlus(){
      this.small_bag++;        
      this.updateCart();
  }
  smallBagMinus(){
    if(this.small_bag >0){
      this.small_bag--;
      this.updateCart();
    }
  }
  updateCart(){    
   this.retrieveSubscription();
  }
  calculateCart(){
    const bag_cost = (this.small_bag * this.smallBagRate +  this.large_bag*this.largeBagRate);
    this.small_bag_amount = this.smallBagRate.toFixed(2);
    this.large_bag_amount = this.largeBagRate.toFixed(2);
    var total_discount:number = 0;
    if(this.subscription.status){
      this.pro_discount = parseFloat((Math.round(((5 * bag_cost / 100) + Number.EPSILON) * 100) / 100).toFixed(2));
      this.delivery_charges = 0.00;          
    }else{
      this.delivery_charges = 4.99;
      this.pro_discount = 0.00;
    }
    total_discount +=  this.coupon_discount;
    total_discount = Math.round((total_discount + Number.EPSILON) * 100) / 100;
    total_discount = total_discount + this.pro_discount;
   
    this.env_fee = (this.largeBagFee * this.large_bag)+(this.smallBagFee * this.small_bag);
    if(this.env_fee < 0){
      this.env_fee = 0;
    }    
    this.sub_total = bag_cost + this.delivery_charges + this.env_fee + (-1) * total_discount +  this.tip ;
    
    this.sub_total = this.sub_total;
    this.sub_total = Math.round((this.sub_total+ Number.EPSILON) * 100) / 100;    
    this.sales_tax = (this.sub_total - this.tip) * this.salesTaxRate / 100;
    if(this.sub_total<0){
      this.sub_total = 0;
      this.sales_tax = 0;      
    }
    this.total = this.sub_total + this.sales_tax;
    if(this.total>0 && this.useWalletBalance && this.customer_balance>0){       
       this.total = this.total - this.customer_balance;       
    }
    if(this.total<0){
      this.total = 0;     
    }
       
    this.sales_tax =Math.round((this.sales_tax + Number.EPSILON) * 100) / 100;
    this.total =(Math.round((this.total + Number.EPSILON) * 100) / 100).toFixed(2);
    if(this.small_bag<=0 && this.large_bag <= 0){          
      this.delivery_charges = 0;
      this.total = 0;
      this.sub_total = 0;
      this.sales_tax =0;
    }
    this.sub_total = this.sub_total.toFixed(2);
    this.sales_tax = this.sales_tax.toFixed(2);
    this.env_fee = this.env_fee.toFixed(2);
    this.delivery_charges = this.delivery_charges.toFixed(2);    
  }
  updateWalletBalanceUsage() {
    const body = {
      customer_id: sessionStorage.getItem('user_id'),  
      use_wallet: this.useWalletBalance
    };    
    this.spinner = true;
    this.userService.updateWalletUsage(body).subscribe(
      (res: any) => {
        this.spinner = false;        
        this.calculateCart();                
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );  
  }
  getCustomerBalance(){
    const body = {      
      customer_id: sessionStorage.getItem('user_id')
      };
      this.spinner = true;
    this.userService.getCustomerBalance(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){                    
          this.useWalletBalance = _res.customer.use_wallet_balance;           
          this.customer_balance = Math.round((_res.balance+ Number.EPSILON) * 100) / 100;                            
        }else{
          this.customer_balance = 0;                              
        }                        
      },
      (_error: any) => {
        this.customer_balance = 0;                                      
      }
    );    
  }
  getBagRates(){
    const body = {      
      cat_id: 1
      };
      this.spinner = true;
    this.userService.getBagRates(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.result.length>0){                    
          this.smallBagRate = res.result[0].amount;           
          this.smallBagFee = res.result[0].bag_fee;           
          this.salesTaxRate= res.result[0].sales_tax;           

          this.largeBagRate = res.result[1].amount;           
          this.largeBagFee = res.result[1].bag_fee;           

        }else{
          this.customer_balance = 0;                              
        }                        
      },
      (_error: any) => {
        this.customer_balance = 0;                                      
      }
    );    
  }
  applyCouponCode(){        
    const body = {
      code: this.code,
      customer_id: sessionStorage.getItem('user_id')
      };
      this.spinner = true;
    this.userService.getCoupon(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){
          
          this.coupon_discount = Math.round((_res.discount+ Number.EPSILON) * 100) / 100;
          if(this.small_bag>0 || this.large_bag>0){
            this.updateCart();
          }          
        }else{
          this.coupon_discount = 0;          
          this.updateCart();
          this.snackBar.open(_res.message+ ' Coupon Code: '+this.code, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });                            
          this.code = '';  
        }                
      },
      (_error: any) => {
        this.spinner = false;
        this.coupon_discount = 0;        
          this.updateCart();
        this.snackBar.open(
          _error.error.message + ' Coupon Code: '+this.code,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
        this.code = '';
      }
    );    
  }
  setCouponCode(e){
    if(e.target.value.length>0){
      this.code = e.target.value;
      this.applyCouponCode();
    }else{
      this.coupon_discount = 0;
      this.updateCart();
      this.code = '';      
    }
  }
  retrieveSubscription(){
    const body = {
      customer_id: sessionStorage.getItem('user_id')
      };
    this.userService.getSubscription(body).subscribe(
      (_res: any) => {
        if(_res.status){          
          if(Subscription.Active === _res.subscription_status){            
            this.subscription.status = true;
          }else if(Subscription.Trialing === _res.subscription_status){            
            this.subscription.status = true;
          }else if(Subscription.Canceled === _res.subscription_status || Subscription.Ended === _res.subscription_status  || Subscription.Unsub === _res.subscription_status){            
            this.subscription.status = false;
          }          
          else{            
            this.subscription.status = false;
          }        
        }else{        
          /*
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });                              
          */
        }
        this.calculateCart();                
      },
      (_error: any) => {
        this.calculateCart();                
        /*
        this.snackBar.open(
          _error.error.message,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
        */
      }
    );
  }
  tipUpdated(e){
    if(Number(e.target.value)>0){
      this.tip = Number(e.target.value);
      this.updateCart();
    }else{
      this.tip = 0;
      this.updateCart();
    }
  }
  handleEmptyInput(event: any){
    if(event.target.value === '') {
      this.address = null;
      this.formatted_address = null
    }
  }
  toggleBookLater() {
    console.log("Address: " + this.address + " formatted_address : "+ this.formatted_address);
    if(this.address === undefined || this.address === null || this.latitude === null || this.latitude === undefined || this.longitude === undefined || this.longitude === null)
    {
      this.snackBar.open('Enter your pickup location address, you must select an address from the location suggestions. For more details please use the delivery instructions box.', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });     
      return;
    }
    if(this.small_bag<=0 && this.large_bag <= 0){
      this.snackBar.open('Add at least one bag please.', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });     
      return;
    }
    this.openBookLaterDialog();
  }
  populateTime() {
    var x = 5;
    var times = [];
    var tt = 0;
    var ap = ['AM', 'PM'];

    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60);
      var mm = tt % 60;
      times[i] =
        ('0' + (hh % 12)).slice(-2) +
        ':' +
        ('0' + mm).slice(-2) +
        ap[Math.floor(hh / 12)];
      tt = tt + x;
    }

    this.timeArray = times;
  }

  bookNow() {
      if(this.address === undefined || this.address === null)
      {
        this.snackBar.open('Enter your pickup location address, you must select an address from the location suggestions. For more details please use the delivery instructions box.', 'Dismiss', {
          duration: 4000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });     
        return;
      }
      if(!this.agree){
        this.snackBar.open('Error: You must agree to the terms and conditions.', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
        return;
      }
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const date = yyyy + '-' + mm + '-' + dd;
      
      if(this.address.geometry == null || this.address.geometry == undefined){
      //  this.latitude = '0.00';
       // this.longitude = '0.00';
        //this.formatted_address=this.address;
      }else{
        this.latitude = this.address.geometry.location.lat();
        this.longitude = this.address.geometry.location.lng();
        this.formatted_address=this.address.formatted_address;
      }     
      if(this.delivery_instructions == undefined){
        this.delivery_instructions = '';
      }
      let tip = this.tip+"";
      console.log(tip);
      const body = {
        mode: 'ridenow',
        customer_id: sessionStorage.getItem('user_id'),
        pick_up: this.formatted_address,
        apartment_number:this.apartment,
        pickup_lat: this.latitude.toString(),
        pickup_lon: this.longitude.toString(),
        drop_location: 'NoDrop',
        drop_lat: this.latitude.toString(),
        drop_lon: this.longitude.toString(),
        vehicle_id: '1',
        service_id: '1',
        pick_up_time: '',
        date: date,
        small_bag: this.small_bag,
        large_bag: this.large_bag,
        delivery_instructions: this.delivery_instructions,
        coupon_code: this.code,
        tip_amount: tip,
      };
      this.submit(body);    
  }

  bookLater() {
    if (this.isLoggedIn()) {
      if(this.address === undefined || this.address === null)
      {
        this.snackBar.open('Enter your pickup location address, you must select an address from the location suggestions. For more details please use the delivery instructions box.', 'Dismiss', {
          duration: 4000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });     
        return;
      }
      if(this.small_bag<=0 && this.large_bag <= 0){
        this.snackBar.open('Add at least one bag please.', 'Dismiss', {
          duration: 4000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });     
        return;
      }
      if (this.timeFrames=== null || this.timeFrames === undefined || this.timeFrames === 0) {
        this.spinner = false;        
        this.snackBar.open(
          'Please select the pickup time',
          'Dismiss',
          {
            duration: 5000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );        
      } else {
        if ((this.bookDate !== null && this.bookDate !== undefined)) {
          
          if(this.address.geometry == null || this.address.geometry == undefined){
          //  this.latitude = '0.00';
           // this.longitude = '0.00';
            //this.formatted_address=this.address;
          }else{
            this.latitude = this.address.geometry.location.lat();
            this.longitude = this.address.geometry.location.lng();
            this.formatted_address=this.address.formatted_address;
          }
          console.log("Date from pickup contro: "  + this.bookDate);
          //const date = this.formatDate(this.bookDate.value);
          const date = this.bookDate;
          console.log("After formatting date: "  + date);
          //const time = new Date;
          if(this.delivery_instructions == undefined){
            this.delivery_instructions = '';
          }
          let tip = this.tip+"";
          console.log(tip);
          
          let time:any;
          this.pickup_time_frames.forEach(element => {
              if(element.id == this.timeFrames){
                time = element.time_limit;
            }
          }); 
          

          const body = {
            mode: 'ridelater',
            customer_id: sessionStorage.getItem('user_id'),
            pick_up: this.formatted_address,
            apartment_number:this.apartment,
            pickup_lat: this.latitude.toString(),
            pickup_lon: this.longitude.toString(),
            drop_location: 'NoDrop',
            drop_lat: this.latitude.toString(),
            drop_lon: this.longitude.toString(),
            vehicle_id: '1',
            service_id: '1',
            date: date,
            small_bag: this.small_bag,
            large_bag: this.large_bag,
            pick_up_time: date+' '+time+":00",
            ride_time: date+' '+time+":00",            
            ride_date: date,
            today_date:date,
            delivery_instructions: this.delivery_instructions,
            coupon_code: this.code,
            tip_amount: tip,
            time_frame_id: this.timeFrames
          };
          if(this.weeklySelected !== "0"){
            body["type"] = this.weeklySelected;
          }
          console.log("Order body : " + body);
         // console.log(body);
          //this.submit(body);
          this.updateCard(body);
        } else {
          this.snackBar.open(
            'Please insert the date for your pickup',
            'Dismiss',
            {
              duration: 5000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            }
          );
        }
      }
    } else {
      const book = 2;
  
    }
  }
  
  isLoggedIn() {
    if ('user_id' in sessionStorage) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  getServerTime(){
    this.bookingService.getServerTime().subscribe(
      (res: any) => {
        this.spinner = false;
        this.server_date_time = res.server_date_time;        
        this.server_date = new Date(res.server_date);
        this.server_date.setTime(this.server_date.getTime() + 6 * 60 * 60 * 1000)
        //this.selectedDate = new FormControl(new Date(res.server_date).getTime() + 15 * 60 * 60 * 1000);
        var ms = new Date(res.server_date).getTime() + 86400000;
        this.selectedDate = new FormControl(new Date(ms));
        this.max_booking_date = new Date(res.server_date); 
        this.max_booking_date.setMonth(this.max_booking_date.getMonth() + 3);
        this.max_booking_date = this.max_booking_date.toISOString().substring(0, 10);
        console.log("Server Max Date" + this.max_booking_date);
        this.server_time = res.server_time;
        this.bookDate = (new Date(this.server_date)).toISOString().substring(0, 10);
        console.log("Server date converted real." + (new Date(this.server_date)));
        console.log("Server date converted ISO String short." + this.bookDate);
        console.log("Server date converted ISO String full." + (new Date(this.server_date)).toISOString());
        this.pickup_date = res.server_date;
        this.picker_date = res.server_date;
        console.log("Server Date" + this.server_date);
        console.log("Server Date real" + res.server_date);
        this.server_date_formatted = res.server_date;
        this.min_calendar_date = new Date(ms);// this.convertTZ(new Date(res.server_date),"US/Central");
      //  this.min_calendar_date.setTime(this.min_calendar_date.getTime() + 5 * 60 * 60 * 1000)
     //   this.min_calendar_date = this.min_calendar_date.toISOString().substring(0, 10)
     //this.min_calendar_date = res.server_date;
        console.log("Converted server date with time for calendar = " + this.min_calendar_date);
        console.log("Server time" + this.server_time);
        console.log("Server date for minimum as formatted" + this.server_date_formatted);
        if(localStorage.getItem('address') && localStorage.getItem('lat') && localStorage.getItem('lng')){         
          this.getPickupTimeFrames();
        }         
      },
      (error: any) => {
        if(localStorage.getItem('address') && localStorage.getItem('lat') && localStorage.getItem('lng')){         
          this.getPickupTimeFrames();
        }
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
      );
  }
  updateCard(orderBody) {
    console.log('edit card: '+ this.card.editCard);
    if(this.card.editCard){    
      this.card.submitted = true;
      // stop here if form is invalid      
      if (this.card.cardForm.invalid) {
        this.snackBar.open(
          'Please fix your card details.',
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );  
        this.card.editCard = true; 
        return;
      }

      const body = {
        id: sessionStorage.getItem('user_id'),
        card_number: this.card.cardForm.controls.ccnumber.value,
        card_month_expiry: this.card.cardForm.controls.expirationMonth.value,
        card_year_expiry: this.card.cardForm.controls.expirationYear.value,
        card_cvv: this.card.cardForm.controls.cvv.value    ,
        postal_code: this.card.cardForm.controls.postal_code.value,
        address1: this.card.cardForm.controls.addressOne.value,
        address2: this.card.cardForm.controls.addressTwo.value,
        city: this.card.cardForm.controls.city.value,
        state: this.card.cardForm.controls.state.value,
        card_user_name:this.card.cardForm.controls.ccname.value,
      };
      this.spinner = true;
        this.userService.updateCard(body).subscribe(
        (_res: any) => {
          this.spinner = false;
          if(_res.status){        
            this.snackBar.open(_res.message, 'Dismiss', {
              duration: 3000,
              panelClass: ['notif-success-green'],
              verticalPosition: 'top',
            });                    
            this.card.cardForm.controls.ccnumber.setValue('xxxxxxxxxxxx'+this.card.cardForm.controls.ccnumber.value.slice(this.card.cardForm.controls.ccnumber.value.length - 4));
            this.card.editCard = false; 
            this.submit(orderBody);
          }else{
            this.snackBar.open(
              _res.message,
              'Dismiss',
              {
                duration: 4000,
                panelClass: ['notif-failure'],
                verticalPosition: 'top',
              }
            );  
            this.card.editCard = true; 
          }        
        },
        (_error: any) => {
          this.spinner = false;
          this.card.submitted = false;
          this.snackBar.open(
            _error.error.message,
            'Dismiss',
            {
              duration: 4000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            }
          );
        }
      );
    }else{
      this.submit(orderBody);
    }
  }
  submit(body: any) {        
    this.spinner = true;
    console.log(body);
    
    // Check if the card data is valid to submit. 
    this.bookingService.create(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if (res.code === 500) {
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 5000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        } else if (res.status) {
          const timeout = 4000;
          this.spinner = false;            
            const dialogRef = this.dialog.open(SuccessMessageComponent, {
              autoFocus: false,
            });
            dialogRef.afterOpened().subscribe(_ => {
              setTimeout(() => {
                 dialogRef.close();
                 this.router.navigate(['account']);
              }, timeout)
            })
        }else{
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 5000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
          if(res.coupon_error){
            this.code = '';      
          }
        }
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  }  
  confirmSchedulePickup(){
  //  this.scheduleLatermodal.dismissAll();
    //this.bookLater();    
    this.wbQuestion = true;
  }
  noWB(){
    this.modalService.dismissAll();
    this.bookLater();
  }
  nextServiecSelection(){    
    if(this.serviceSelection == undefined){
       this.snackBar.open('Please select one service option.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      }); 
      return; 
    }
    if(this.serviceSelection == "2"){
      console.log("One time service selected");
      this.modalService.dismissAll();
      this.bookLater();
    }else{
      this.wbOptions = true;
      this.wbQuestion = false;
    }
    
    console.log("Service selection: " + this.serviceSelection);
    console.log("Weekly selection: " + this.weeklySelection);
    console.log("Weekly choosen: " + this.weeklySelected);
  }
  backWeekly(){
    this.wbOptions = false;
    this.wbQuestion = true;
    console.log("Service selection: " + this.serviceSelection);
    console.log("Weekly selection: " + this.weeklySelection);
    console.log("Weekly choosen: " + this.weeklySelected);
  }
  confirmWB(){
    if(this.weeklySelection == undefined){      
      this.snackBar.open('Please select your desired service and confirm.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      }); 
      return; 
    }
    this.modalService.dismissAll();
    this.bookLater();
  }
  confirmOneTime(){
    this.weeklySelected = "0";
    this.modalService.dismissAll();
    this.bookLater();
  }
  openBookLaterDialog() {
    console.log("pickup date: "+ this.pickup_date);
    this.dattimemodal = this.modalService.open(this.scheduleLatermodal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result != undefined && result === 'Confirm click'){        
        this.wbQuestion = true;
      }      
    }, (reason) => {      
    });  
    
  }
  getPickupTimeFrames(){
    const body = {
      type: 1,
      customer_id: sessionStorage.getItem('user_id'),  
      service_id: "1",
      date: this.pickup_date,
      pick_up: this.formatted_address,
      latitude:  parseFloat(this.latitude),
      longitude: parseFloat(this.longitude),
    };
    console.log("Order time frame API called.");
    this.spinner = true;
    this.bookingService.getPickupTimeFrames(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){
          this.pickup_time_frames = res.time_frames;                             
          this.available_days = res.available_days;
          this.hours = new Date().getUTCHours()-5;
          this.minutes = new Date().getMinutes();
          console.log("Pick up control date "+ this.pickup_date);
          if(this.server_time){
            this.hours = this.server_time;
            console.log("server time: " + this.server_time);
          }          
          console.log("Hours Now" + this.hours);
          
          var p_date =  new Date(this.pickup_date);
          p_date.setTime(p_date.getTime() + 6 * 60 * 60 * 1000)
          var s_date = new Date(this.server_date_formatted);
          s_date.setTime(s_date.getTime() + 6 * 60 * 60 * 1000)
          s_date.setHours(0,0,0,0);
          p_date.setHours(0,0,0,0);
          this.pickup_time_frames.forEach(element => {
            console.log("Time Limit " + element.time_limit);          
            console.log(" Element 2: " + element.time_limit + " Status " + element.status + " element.status == 0 " + (element.status == 0));          

            if(element.status){
           //   element['display'] = false;              
              console.log(" Server date: " + s_date + " Pickup Date: " + p_date + " pickup_date  this.server_date =" + (p_date.getTime() == s_date.getTime()))
              if(p_date.getTime() == s_date.getTime()){
                console.log(" Hours = " + this.hours + " time hours = " + element.time_limit + " Condition check = " + (parseInt(this.hours) <  parseInt(element.time_limit)))
                if((parseInt(this.hours) <  parseInt(element.time_limit))){
                  console.log(" condition met = ")
               //   element['display'] = false;
                }else{
                  console.log(" Not condition met = ")
                  element['display'] = true;
                }
              }
            }else{
              element['display'] = true;
            }            
            
            /*
            if(element.status){
              element['display'] = false;
            }else{
              element['display'] = true;
            }*/
          }); 
          console.log('Time frames');
          console.log(this.pickup_time_frames);


          // Create a filter array for disabling the days in calendar
          var availableDays = new Array(this.available_days.length);  
        }         
        else{                  
          this.pickup_time_frames = res.time_frames;                             
          this.available_days = null;
          this.hours = new Date().getUTCHours()-5;
          this.minutes = new Date().getMinutes();
          console.log("Pick up control date "+ this.pickup_date);
          if(this.server_time){
            this.hours = this.server_time;
            console.log("server time: " + this.server_time);
          }          
          console.log("Hours Now" + this.hours);
          
          this.pickup_time_frames.forEach(element => {
            console.log("Time Limit " + element.time_limit);          
            console.log(" Element : " + element.time_limit + " Status " + element.status + " element.status == 0 " + (element.status == 0));                     
            element['display'] = true;            
          }); 
          console.log('Time frames');
          console.log(this.pickup_time_frames);             
            this.snackBar.open(res.message, 'Dismiss', {
              duration: 3000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            });
        }
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  } 
  timeframeChange(){
    this.hours = new Date().getHours();
    this.minutes = new Date().getMinutes();
    this.timeFrameSelected = this.timeFrames;
  }
  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if(type == "change"){
      console.log("date changed value: " + event.value);
      var d = new Date(event.value)
      let date = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()      
      this.pickup_date = date;
      this.bookDate = date;
      console.log("pickup date: "+ d);
      //console.log("selected date: "+  new Date(this.selectedDate));
      this.hours = new Date().getUTCHours()-5;
      this.minutes = new Date().getMinutes();
     // let today_date = this.server_date.toISOString().substring(0, 10);
     // this.server_date = today_date;
      let new_date = this.convertTZ(date, "US/Central");  
      console.log("New date after conversion: " + new_date);
      if(this.server_time){
        this.hours = this.server_time;
        console.log("server time: " + this.server_time);
      }
      console.log("book date" + this.bookDate);
      this.getPickupTimeFrames();
    }    
  }
  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();    
    if(this.available_days == null){
      return true;
    }
    this.available_days.forEach(element => {
      console.log("available day : " + (element.id - 1))
    });
    console.log("Day  : " + day + "  found: " +  (this.available_days.find(element => ( element.id ) === (day+1)))) ;
   
   return (this.available_days.find(element => ( element.id ) === (day+1)));
  }
  dateChanged(date){
    this.pickup_date = date.value;
    console.log("pickup date: "+ this.pickup_date);
    //this.hours = new Date().getHours();
    this.hours = new Date().getUTCHours()-5;
    this.minutes = new Date().getMinutes();
    //let today_date = ((new Date()).toISOString().substring(0, 10));
    let today_date = this.server_date.toISOString().substring(0, 10);
    let new_date = this.convertTZ(date, "America/Houston");  
    console.log("New date after conversion: " + new_date);
    if(this.server_time){
      this.hours = this.server_time;
      console.log("server time: " + this.server_time);
    }
    console.log("book date" + this.bookDate);
    this.getPickupTimeFrames();
    /*
    if(today_date < date.value){            
      this.pickup_time_frames.forEach(element => {
          element['display'] = false;        
      }); 
      console.log("today_date < date.value today_date: " + today_date + "   Selected date: " + date.value);
    }    
  
    if(today_date == date.value){
      this.timeFrames = 0;
      this.pickup_time_frames.forEach(element => {
        if(this.hours < element.time_limit){
          element['display'] = false;
        }else{
          element['display'] = true;
        }
      }); 
      console.log("today_date == date.value today_date: " + today_date + "   Selected date: " + date.value);
    }
    if(today_date > date.value){
      this.timeFrames = 0;
      this.pickup_time_frames.forEach(element => {
          element['display'] = true;        
      }); 
      console.log("today_date > date.value today_date: " + today_date + "   Selected date: " + date.value);
    }
    */
    console.log('Time frames');
    console.log(this.pickup_time_frames);    
  }
  radioChange(event: MatRadioChange) {        
    console.log("Selected value: " + event.value);
    this.weeklySelected = event.value;
  }
  radioServiceChange(event: MatRadioChange) {        
    console.log("Service value: " + event.value);    
    if(event.value == "2"){
      this.oneTimeservice = true;
    }else{
      this.oneTimeservice = false;
    }
  }
}