<div class="wrapper">
  <div id="formContent">
    <h2 class="active">Sign In to continue</h2>
    <br />
    <form [formGroup]="userForm">      
      <label class='required'>Email</label>
      <input
        type="email"
        placeholder="Email"
        class="swal2-input"
        formControlName="email"
        required
      />      
      <label class='required'>Password</label>
      <input
        type="password"
        placeholder="Password"
        class="swal2-input"
        formControlName="password"
        required
      />
      <button type="submit" (click)="login()">Login</button>
    </form>
    <br />
    <hr />
    <br />
    <p>Don't have an account? <a (click)="signup()"  style="color: #61aeef;">Sign Up</a></p>
    <p><a (click)="forgetPassword()"  style="color: #61aeef;">Forget Password?</a></p>
  </div>
</div>

<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
