import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/login/login.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceareaComponent } from 'src/app/pages/servicearea/servicearea.component';
import { UserService } from '../../core/services/user.service';
@Component({
  selector: 'app-crossroads',
  templateUrl: './crossroads.component.html',
  styleUrls: ['./crossroads.component.css']
})
export class CrossroadsComponent implements OnInit {

  displayedColumns: string[] = [
    'position',
    'name',
    'weight',
    'symbol',
    'actions',
  ];
  spinner: boolean = false;
  address: any;
  constructor(public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,  
      
    private userService: UserService) {}

  ngOnInit(): void {}
  isLoggedIn() {
    if ('user_id' in sessionStorage) {
      return true;
    } else {
      return false;
    }
  }
  openLoginModal() {
    this.dialog.open(LoginComponent, {
      autoFocus: false,
    });    
  }
  create() {
    if(this.address === undefined || this.address === null || this.address.geometry == null || this.address.geometry == undefined){
      this.snackBar.open('Enter your pickup location address, you must select an address from the location suggestions. For more details please use the delivery instructions box.', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });     
      return;
    }                 
    localStorage.setItem('address',this.address.formatted_address);
    localStorage.setItem('lat',this.address.geometry.location.lat());
    localStorage.setItem('lng',this.address.geometry.location.lng());    
    if(this.isLoggedIn()){      
      this.router.navigate(['/plans']);   
    }else{
      this.openLoginModal();             
    }    
  }
  checkServiceArea(){
    if(this.address === undefined || this.address === null || this.address.geometry == null || this.address.geometry == undefined){
      this.snackBar.open('Enter your pickup location address, you must select an address from the location suggestions. For more details please use the delivery instructions box.', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });     
      return;
    }                 
    localStorage.setItem('address',this.address.formatted_address);
    localStorage.setItem('lat',this.address.geometry.location.lat());
    localStorage.setItem('lng',this.address.geometry.location.lng());    
     //find country name
     let state='',city='',country='',political='',neighborhood='';
     
     for (var i=0; i<this.address.address_components.length; i++) {
      for (var b=0;b<this.address.address_components[i].types.length;b++) {
      //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
          console.log("Type = " + this.address.address_components[i].types[b] + "   Name" + this.address.address_components[i].long_name);
          if (this.address.address_components[i].types[b] == "administrative_area_level_1") {
              //this is the object you are looking for
              state= this.address.address_components[i].long_name;                            
          }
          if (this.address.address_components[i].types[b] == "locality") {
            //this is the object you are looking for
            city= this.address.address_components[i].long_name;            
            }
            if (this.address.address_components[i].types[b] == "neighborhood") {
              //this is the object you are looking for
              neighborhood= this.address.address_components[i].long_name;            
              }
              if (this.address.address_components[i].types[b] == "political") {
                //this is the object you are looking for
                political= this.address.address_components[i].long_name;            
                }
        if (this.address.address_components[i].types[b] == "country") {
          //this is the object you are looking for
          country= this.address.address_components[i].long_name;          
        }
      }
    }

    console.log('city before ' + city);
    if(city == ''){
      city = neighborhood;
    }
    console.log('city before ' + city);
    const body = {
      city: city,
      state: state,
      country: country,
      address:this.address.formatted_address,
      lat:this.address.geometry.location.lat(),
      lng:this.address.geometry.location.lng()
    };
    localStorage.setItem('service_coverage','0');
    this.spinner = true;
    this.userService.checkServiceArea(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){
          if(res.service_area){
            localStorage.setItem('service_coverage','1');
          }else{
            localStorage.setItem('service_coverage','0');
          }
        }else{
          localStorage.setItem('service_coverage','0');
        }        
        this.dialog.open(ServiceareaComponent, {
          width: '90vw',        
          height: '64vh',          
          hasBackdrop: false,                
          panelClass: ['custom-modalbox']
        });             
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );    
  }
  close() { 
    this.dialog.closeAll();
  }
}

