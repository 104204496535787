import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public httpOptions: any;

  constructor(private http: HttpClient) {}

  updateUser(body: any) {
    return this.http.post<any>(`${environment.api}/update-user`, body);
  }
  updateWalletUsage(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/update-wallet-usage`, body);
  }
  forgetPassword(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/forgetPassword`, body);
  }
  changePassword(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/changePassword`, body);
  }
  checkServiceArea(body: any) {
    return this.http.post<any>(`${environment.apidirect}/booking/checkServiceArea`, body);
  }
  addServiceAreaRequest(body: any) {
    return this.http.post<any>(`${environment.apidirect}/booking/addCoverageRequest`, body);
  }
  updateCard(body: {
    id: string;
    card_number: any;
    card_month_expiry: any;
    card_year_expiry: any;
    card_cvv: any;
    postal_code: any;
    address1: any;
    address2: any;
    city: any;
    state: any;    
  }) {
    return this.http.post<any>(`${environment.api}/update-card`, body);
  }

  getUser(body: any) {
    return this.http.post<any>(`${environment.api}/get-user`, body);
  }
  getStripeCard(body: any) {
    return this.http.post<any>(`${environment.api}/card-detail`, body);
  }
  addSubscription(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/createSubscription`, body);
  }
  cancelSubscription(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/cancelSubscription`, body);
  }
  getSubscription(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/retrieveSubscription`, body);
  }
  activateSubscription(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/activateSubscription`, body);
  }
  getCoupon(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/getCoupon`, body);
  }        
  getCustomerBalance(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/getCustomerBalance`, body);
  }   
  getDriver(body:any){
    return this.http.post<any>(`${environment.apidirect}/driver/getDriver`, body);
  }   
  getCustomer(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/getCustomer`, body);
  }
  getPreferredDrivers(body: any) {
    return this.http.post<any>(`${environment.apidirect}/customer/getReferrals`, body);
  }  
  updateReferral(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/updateReferral`, body);
  }  
  removePreferredPro(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/removePreferredPro`, body);
  }    
  addPreferredParterCode(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/addPreferredPro`, body);
  }      
  verifyPreferredProCode(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/verifyPreferredProCode`, body);
  }        
  getWalletHistory(body:any){
    return this.http.post<any>(`${environment.apidirect}/customer/getWalletHistory`, body);
  } 
  getBagRates(body:any){
    return this.http.post<any>(`${environment.apidirect}/subCategory`, body);
  }   
}

