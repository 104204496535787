import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribeComponent } from 'src/app/pages/subscribe/subscribe.component';
import { SuccessSubscribeComponent } from 'src/app/components/success-subscribe/success-subscribe.component';
import { Router } from '@angular/router';

enum Subscription{
  Unsub = 'unsub',
  Canceled = 'canceled',
  Ended = 'ended',
  Active = 'active',
  Trialing = 'trialing'
}


@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {

  new_subscriber:boolean = false;
  old_subscriber:boolean = false;
  active_subscriber:boolean = false;
  trial_subscriber:boolean = false;
  
  constructor(        
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar) 
    { }
  
  ngOnInit(): void {
    const subscrip = localStorage.getItem('subscription');
    if(subscrip === Subscription.Canceled || subscrip === Subscription.Ended){
      this.old_subscriber = true;
    }else if(subscrip === Subscription.Active){
      this.active_subscriber = true;
      this.router.navigate(['/order']);   
    }else if(subscrip === Subscription.Trialing){
      this.trial_subscriber = true;
      this.router.navigate(['/order']);   
    }else if(subscrip === Subscription.Unsub){
      this.new_subscriber = true;
    }else{
      this.new_subscriber = true;
    }
  } 
  showSubscription(){    
    const dialogRef = this.dialog.open(SubscribeComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((success) => {
      if (success) {
        this.dialog.open(SuccessSubscribeComponent);
      }
    });
  }   
  schedule(){
    this.router.navigate(['/order']);          
  }
}
