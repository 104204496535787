<div class="page-content" style="background-color: #fff;">
    <div class="container">                 
        <div class="row justify-content-center">
            <h3>ORDER # {{booking_id}}</h3>
        </div>
        <hr/>
        <div class="row">
            <div class="col-12">
                <mat-card class="example-card">
                    <mat-card-header>
                      <div mat-card-avatar class="example-header-image"></div>
                      <mat-card-title>
                        <img src="assets/images/marker.png" class="img-fluid px-1" alt="Address" />{{address}}                        
                      </mat-card-title>                       
                      <mat-card-subtitle *ngIf="apartment_number">Apartment number: {{apartment_number}}</mat-card-subtitle>
                    </mat-card-header>                    
                    <hr/>
                    <mat-card-content>                         
                        <!--
                        <div class="row h-100 justify-content-center align-items-center">
                            <div class="col-12 text-center">
                                <img  *ngFor="let a of small_bag_array; let index = index" src="assets/images/small-bag-blue-91-130.png" alt="Small bags">
                                <img *ngFor="let a of large_bag_array; let index = index" src="assets/images/large-bag.png" alt="large bags">                        
                            </div>
                        </div>
                        -->
                        <!-- <div class="mt-2" *ngIf="small_bag">Number of small bag(s): {{small_bag}}</div>
                        <div class="mt-2" *ngIf="large_bag">Number of large bag(s): {{large_bag}}</div>
                        -->              
                        <!--<hr/>-->                       
                        <div class="row mt-3">
                            <div class="col-6">
                                Driver: <mat-label><b> {{driver_name}}</b></mat-label>
                            </div>
                            <div class="col-6">
                                Booking ID: <b>{{booking_id}}</b>
                            </div>                            
                        </div>          
                        <div class="row mt-3">
                            <div class="col-6">
                                Notes: <b>{{notes}}</b>
                            </div>
                            <div class="col-6">
                                Delivery Instructions: <b>{{instructions}}</b>
                            </div>                            
                        </div>          
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-6">                                                                               
                                <mat-chip-list aria-label="Fish selection">
                                    <mat-chip [ngStyle]="{'background-color': status_background_color, color: status_color}" color="{{status_color}}" selected>{{short_status}}</mat-chip>                                
                                </mat-chip-list>                                                                
                            </div>                            
                        </div>
                        <div class="row" *ngIf="!job_cancelled">
                            <div class="col-sm-12 col-md-6">                                                                               
                                <button class="btn btn-danger mt-2" (click)="cancelOrderConfirmation()"><i class="fas fa-window-close"></i> Cancel Order</button>                                                                        
                            </div>
                        </div>
                        <hr/>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-4">                                   
                                <button class="btn btn-primary mt-2" (click)="open(mymodal)"><i class="fas fa-comment-dots"></i> Send Text to Partner</button>                                
                            </div>
                            <div class="col-sm-12 col-md-4">                                   
                                <button class="btn btn-primary mt-2" (click)="callDriver()"><i class="fas fa-phone-volume"></i> Call Partner {{driver_name}}</button>                                
                            </div>
                            <div class="col-sm-12 col-md-4 mt-2">                                                                               
                                <button mat-raised-button color="primary" [ngStyle]="{'background-color': status_background_color, color: status_color}" (click)="contactSupport()"><mat-icon><span class="material-icons-outlined">support_agent</span></mat-icon> Contact Support</button>                                
                            </div>
                        </div>
                        <hr/>                 
                        <div *ngIf="job_cancelled">
                            <mat-chip-list aria-label="Job Cancelled">                                
                                <mat-chip [ngStyle]="{'background-color': status_background_color, color: status_color}" color="{{status_color}}" selected>{{status}}</mat-chip>                                
                            </mat-chip-list>
                        </div>                        
                        <div *ngIf="!job_cancelled" >
                            <div [ngClass]="i == stepper_index ? 'step step-active' : 'step'" *ngFor="let s of statuses;index as i" >
                                <div>
                                <div *ngIf="i >= stepper_index" class="circle">{{i+1}}</div>
                                <div  *ngIf="i < stepper_index" class="circle"><i class="fas fa-check"></i></div>
                                </div>
                                <div>
                                <div class="title">{{s.short_status}}</div>
                                <div class="caption">{{s.status}}</div>
                                </div>
                          </div>
                        </div>                        
                    </mat-card-content>                    
                </mat-card>
            </div>
        </div>
    </div>    
</div>
<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Send Text to Partner</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <textarea
        type="text"
        placeholder="Enter your message here..."                                                       
        [(ngModel)]="message"                                                                                                                                  
        style="width: 100%;"
        ></textarea>    
    </div>
    <div class="modal-footer">
      <button type="btn btn-danger" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
      <button class="btn btn-primary mt-2" (click)="messageDriver()"><i class="fas fa-comment-dots"></i> Send Text to Partner</button>  
    </div>
  </ng-template>