  <!----------- footer start --------->
  <section id="ld_footer" class="space-pt space-pb">
    <div class="container">
        <div class="ld-footer-wrap text-center">
            <ul>
                    <li><a href="https://www.facebook.com/thereallaundryondemand"><img src="assets/images/fb-icon.png"></a></li>
                    <li><a href="https://www.linkedin.com/company/laundry-on-demand"><img src="assets/images/ln-icon.png"></a></li>
                    <li><a href="https://www.instagram.com/laundry_on_demand/"><img src="assets/images/ig-icon.png"></a></li>
                    <li><a href="https://twitter.com/DemandLaundry"><img src="assets/images/tw-icon.png"></a></li>
            </ul>
            <div class="ld-footer-link">
                <a href="mailto:support@laundryod.com"> Support@laundryod.com</a><br/>
                <a href="tel:4697088836"> 469-708-8836 </a>

            </div>
            <p>Copyright 2021 Laundryod | All Rights Reserved. Terms & conditions</p>
    </div>    
</div>
</section>
 <!---------- footer end ------------->