<div class="page-content">
    <mat-card>      
        <form [formGroup]="updatePasswordForm">
            <div class="form-wrap">          
                <div class="form-inr">
                    <div class="row">
                        <div class="col-12">                
                            <div class="card">
                                <div class="card-header">
                                    Enter New Password
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                              <label class='required'>Code</label>
                                              <input type="text" formControlName="code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.code.errors }"  />
                                              <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                                  <div *ngIf="f.code.errors.required">Code is required</div>                                                  
                                              </div>  
                                            </div>
                                        </div>   
                                        <div class="col-12">
                                          <div class="form-group">
                                            <label class='required'>New Password</label>
                                            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  maxlength="50"  minlength="8"  />
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors?.minlength">Password must be minimum 8 characters long</div>                                        
                                                <div *ngIf="f.password.errors?.maxlength">Password must be maximum 50 characters long</div>                                        
                                            </div>  
                                          </div>
                                        </div>                                   
                                        <div class="col-12">
                                            <label class='required'>Confirm New Password</label>
                                            <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" maxlength="50"  minlength="8" />
                                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>                  
                                                <div *ngIf="f.confirmPassword.errors?.minlength">Confirm password must be minimum 8 characters long</div>                                        
                                                <div *ngIf="f.confirmPassword.errors?.maxlength">Confirm password must be maximum 50 characters long</div>                                                                                                          
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button mat-raised-button color="primary" (click)="resetPassword()">
                                        Confirm New Password
                                    </button>
                                </div>
                            </div>   
                        </div>  
                    </div>
                </div>
            </div>
        </form>
    </mat-card>    
</div>