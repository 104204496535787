<div mat-dialog-content class="dialog-width">
    <div class="card">    
      <img class="mx-auto d-block" src="../../../assets/confirm-booking.png" alt="confirmation" />
      <div class="card-body">    
        <p class="text-justify mt-1" style="color: #61aeef">
            You are all set. Now let's knock out some laundry!
        </p>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" (click)="scheduleNow()">Schedule Now!</button>
            <button class="btn btn-primary ml-2" (click)="close()">Close</button>
      </div>
    </div>
    </div>