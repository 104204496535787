<div class="page-content" style="background-color: #fff;">
    <section class="pricing  mt-5 py-5">
        <div class="container">                 
            <div class="form-wrap">                        
                <div class="form-inr">
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            <h4>Schedule Your Pick UP Now!</h4>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <mat-card>                                
                                    <textarea
                                        type="text"
                                        placeholder="Enter your Address here..."
                                        matGoogleMapsAutocomplete                     
                                        [(ngModel)]="address"                                                  
                                        value="{{formatted_address}}"
                                        (onAutocompleteSelected)="create()"
                                        (change)="handleEmptyInput($event)"
                                        style="width: 100%;"
                                    ></textarea>                                    
                                    <!--
                                    <input
                                        type="text"
                                        placeholder="Enter apartment number here..."                                                
                                        [(ngModel)]="apartment"                                              
                                        name="apartment"
                                        class="ml-2"                                                
                                        />
                                        -->                                              
                                    
                                
                                </mat-card>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <mat-card>
                                <textarea                                        
                                        type="text"
                                        class="delivery_instructions"
                                        placeholder="Delivery Instructions: i.e. Apt #"
                                        [(ngModel)]="delivery_instructions"                                                                                      
                                        name="delivery_instructions"></textarea>                                                                          
                            </mat-card>
                        </div>
                    </div>
                    <hr/>
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            <h4>How much Laundry Do You Have?</h4>
                        </div>
                    </div>
                    <div class="row mt-2 d-block d-sm-block d-md-block d-lg-none">
                        <div class="col-12">                                  
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Which Bag Should I Choose? <span class="text-info">Click here</span>
                                    </mat-panel-title>                                            
                                    </mat-expansion-panel-header>
                                    <!--<p>Hey...Thanks for giving Laundry On Demand a try. Here sire a few things to know for your first time. Please put your laundry out in trash bags the first time. Kitchen trash bags are the best. If you have a specific detergent you prefer, please include it with the laundry. We use various scented detergent by default. If you would like your clothes hung, please send hangers and have a spot for delivery setup. Thats it. You have knocked out the laundry.</p>-->
                                    <img src="assets/images/ChooseBagLauraApproved.png" class="img-fluid" alt="step-1" />       
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>                                                                
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card mb-5 mb-lg-0 text-center h-100 ">                
                                        <div class="card-header">
                                            <h5 class="card-title text-muted text-uppercase text-center">Small Bag</h5>                
                                            <h6 class="card-price text-center">$30.00<span class="period">/BAG</span></h6>                                            
                                        </div>
                                        <div class="card-body align-items-center d-flex justify-content-center">
                                            <img src="assets/images/small-bag-blue-91-130.png" class="img-fluid" alt="step-1" />                                                                                                           
                                        </div>                        
                                        <div class="card-footer">                                                
                                            <button (click)="smallBagMinus()"><i class="fas fa-minus">  </i></button>
                                            {{small_bag}}
                                            <button  (click)="smallBagPlus()"> <i class="fas fa-plus">  </i> </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card mb-5 mb-lg-0 text-center">                
                                        <div class="card-header">
                                            <h5 class="card-title text-muted text-uppercase text-center">Large Bag</h5>                
                                            <h6 class="card-price text-center">$45.00<span class="period">/BAG</span></h6>
                                        </div>
                                        <div class="card-body align-items-center d-flex justify-content-center">
                                            <img src="assets/images/large-bag.png" class="img-fluid" alt="step-1" />                                                                                        
                                        </div>
                                        <div class="card-footer">
                                            <button (click)="largeBagMinus()"><i class="fas fa-minus">  </i></button>
                                            {{large_bag}}
                                            <button  (click)="largeBagPlus()"> <i class="fas fa-plus">  </i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2 d-lg-block d-none">
                                <div class="col-12">                                  
                                    <mat-accordion>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Which Bag Should I Choose? <span class="text-info">Click here</span>
                                            </mat-panel-title>                                            
                                            </mat-expansion-panel-header>
                                            <!--<p>Hey...Thanks for giving Laundry On Demand a try. Here sire a few things to know for your first time. Please put your laundry out in trash bags the first time. Kitchen trash bags are the best. If you have a specific detergent you prefer, please include it with the laundry. We use various scented detergent by default. If you would like your clothes hung, please send hangers and have a spot for delivery setup. Thats it. You have knocked out the laundry.</p>-->
                                            <img src="assets/images/ChooseBagLauraApproved.png" class="img-fluid" alt="step-1" />       
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>                                                                
                            </div>
                        </div>                        
                        <div class="col-lg-6">
                            <div class="card mb-5 mb-lg-0">                
                                <div class="card-header">
                                    <h4>Estimated Charges</h4>
                                </div>
                                <div class="card-body">
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label *ngIf="small_bag>0" class="mr-auto">Small Bag(s)</label>    
                                        </div>
                                        <div class="col-6 text-right">
                                            <label *ngIf="small_bag>0" class="ml-5">${{small_bag_amount}}</label>    
                                        </div>                                                                                 
                                    </div>
                                    <hr *ngIf="small_bag>0">
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label *ngIf="large_bag>0" class="mr-auto">Large Bag(s)</label>
                                        </div>
                                        <div class="col-6 text-right">
                                            <label *ngIf="large_bag>0" class="ml-5">${{large_bag_amount}}</label>
                                        </div>                                                                                                                                                                                                 
                                    </div>
                                    <hr *ngIf="large_bag>0">                                    
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label class="mr-auto">Delivery Charges</label>    
                                        </div>
                                        <div class="col-6 text-right">
                                            <label class="ml-5">${{delivery_charges}}</label>    
                                        </div>                                                                                 
                                    </div>
                                    <hr>
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label class="mr-auto">Environmental Fee</label>    
                                        </div>
                                        <div class="col-6 text-right">
                                            <label class="ml-5">${{env_fee}}</label>    
                                        </div>                                                                                 
                                    </div>
                                    <hr *ngIf="subscription.status">
                                    <div *ngIf="subscription.status">
                                        <div class="row">
                                            <div class="col-6 mr-auto">
                                                <label class="mr-auto">Pro discount</label>
                                            </div>
                                            <div class="col-6 text-right">
                                                <label class="text-success text-right">-${{pro_discount}}</label>
                                            </div>
                                        </div>                                                                                
                                    </div>
                                    <hr *ngIf="coupon_discount">
                                    <div *ngIf="coupon_discount">
                                        <div class="row">
                                            <div class="col-6 mr-auto">
                                                <label class="mr-auto">Coupon discount</label>
                                            </div>
                                            <div class="col-6 text-right">
                                                <label class="text-success text-right">-${{coupon_discount}}</label>        
                                            </div>
                                        </div>                                                                                
                                    </div>                                                                        
                                    <hr *ngIf="customer_balance>0 && (small_bag >0||large_bag >0)">
                                    <div *ngIf="customer_balance>0 && (small_bag >0||large_bag >0)">
                                        <div class="row">
                                            <div class="col-6 mr-auto">
                                                <mat-slide-toggle  [(ngModel)]="useWalletBalance"  (change)="updateWalletBalanceUsage()" [ngModelOptions]="{standalone: true}">Use Wallet Balance                          
                                                </mat-slide-toggle>
                                            </div>
                                            <div class="col-6 text-success text-right">                                     
                                                <label class="text-success text-right" *ngIf="this.useWalletBalance">-${{customer_balance}}</label>                                                                                                                                                                         
                                            </div>
                                        </div>                                                                                
                                    </div>
                                    <hr>
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label class="mr-auto">Sub Total</label>    
                                        </div>
                                        <div class="col-6 text-right">
                                            <label class="ml-5">${{sub_total}}</label>    
                                        </div>                                                                                 
                                    </div>
                                    <hr>
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label class="mr-auto">Sales Tax</label>    
                                        </div>
                                        <div class="col-6 text-right">
                                            <label class="ml-5">${{sales_tax}}</label>    
                                        </div>                                         
                                    </div>
                                    <hr *ngIf="tip">
                                    <div *ngIf="tip">
                                        <div class="row">
                                            <div class="col-6 mr-auto">
                                                <label class="mr-auto">Tip amount</label>
                                            </div>
                                            <div class="col-6 text-right">
                                                <label class="text-right">${{tip}}</label>
                                            </div>
                                        </div>                                                                                
                                    </div>
                                    <hr>
                                    <div class="row">                                        
                                        <div class="col-6 mr-auto">
                                            <label class="mr-auto">Estimated Total</label>
                                        </div>
                                        <div class="col-6 text-right">
                                            <label class="ml-5">${{total}}</label>    
                                        </div>                                                                                 
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div class="row">                                        
                                        <div class="col-12">
                                            <label><b>There will be a temporary hold for this amount to ensure there are enough funds to complete the order. Your final charge will be verified by the Laundry Pro at the time of pick up. Your bank should remove the authorization hold on your card within 3-5 business days.</b></label>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <hr/>
                    <div class="row mt-2">
                        <div class="col-12">
                            <input
                                type="text"
                                placeholder="Coupon code"                                                
                                [(ngModel)] = "code"                                                                                                                                                                                  
                            />                          
                            <button class="btn btn-primary mt-2 ml-2" (click)="applyCouponCode()">Apply Coupon Code</button>                                                      
                        </div>                        
                    </div>    
                    <div class="row mt-2">
                        <div class="col-12">
                            <label>Would you like to add tip?</label>
                            <input
                                class="ml-2"
                                type="text"
                                placeholder="Tip"                                                
                                [(ngModel)]="tip"                                                                                      
                                (change)="tipUpdated($event)"
                            />                          
                        </div>                                
                    </div>                        
                    <!--
                    <div class="row"*ngIf="this.toggle">                                
                        <div class="col-12 ml-auto">
                       
                            <mat-form-field appearance="fill">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="bookDate" name="bookDate" [formControl]="bookTime" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            
                            <mat-form-field class="ml-2" appearance="fill">
                                <mat-label>Choose a time: </mat-label>
                                <input matInput type="time" name="bookTime" [formControl]="bookTime" [(ngModel)]="test">
                            </mat-form-field>
                        </div>
                    </div>      
                    -->                                          
                    <div class="row">
                        <div class="col-12">
                            <app-card></app-card>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" [(ngModel)]="agree" class="form-check-input" required>I acknowledge the cancellation policy. You may cancel the service at any time prior to a laundry partner accepting the order. After the partner has accepted the order you may cancel but will be charged for a small bag($30) and any fees associated with that order.
                                </label>                  
                            </div>                                
                        </div>
                    </div>                                                  
                    <div class="row">
                        <div class="modal-button">
                            <div *ngIf="!this.toggle">
                                <button class="btn btn-primary" (click)="toggleBookLater()">
                                Schedule Now
                                </button>
                                &nbsp;
                                <button class="btn btn-primary hide-element" (click)="bookNow()">Book Now!</button>
                            </div>                                  
                            <div *ngIf="this.toggle">
                                <button class="btn btn-primary" (click)="toggleBookLater()">
                                Return
                                </button>
                                &nbsp;
                                <button class="btn btn-primary" (click)="bookLater()">Confirm</button>
                            </div>
                            </div>
                    </div>
                </div>
            </div>                    
        </div>  
    </section>
</div>
<ng-template #scheduleLatermodal let-modal>	
    <div class="modal-header confirm-modal-header">	
      <h4 class="modal-title confirm-modal-heading" id="modal-basic-title">Schedule Your Pickup Now!</h4>	
      <button type="button" class="close close-icon" aria-label="Close" (click)="modal.dismiss()">	
        <span aria-hidden="true">×</span>	
      </button>	
    </div>	
    <div class="modal-body">	
        <div class="row">	
            <div class="col-12 my-2">	
                <mat-form-field>
                    <mat-label>Pickup Date</mat-label>
                    <input matInput [matDatepicker]="picker" class="mx-2" (dateChange)="addEvent('change', $event)" [formControl]="selectedDate" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
               <!-- <input type="date" class="mx-2" id="input_date" name="input_date" [min]="server_date_formatted" [max]="max_booking_date" [formControl]="bookDate" (change)="dateChanged(bookDate)">	-->
            </div>	
            <div class="col-12 my-2">	
              <!--  <label for="bookTime">Time</label>	-->
                <input type="time" class="hide-element" id="bookTime" name="bookTime" [formControl]="bookTime" focus="true">     	
                <select name="timeFrames"  [(ngModel)]="timeFrames" (change)="timeframeChange()">	
                    <option [value]="0" selected>Select Pickup Time</option>	
                    <option *ngFor="let pickup_time of pickup_time_frames;" [value]="pickup_time.id" [disabled]="pickup_time.display">	
                      {{pickup_time.display_text}}	
                    </option>	
                </select>                	
            </div>  
            <div class="col-12 my-2">
                <!--
                <div class="row" *ngIf="wbQuestion">
                    <div class="col-12">
                        <p>Want to set this up weekly or biweekly*</p>
                    </div>                    
                </div>
                -->
                <div class="row" *ngIf="wbQuestion">
                    <div class="col-12">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="serviceSelection">
                       <!-- <mat-radio-button (change)="radioChange($event)" [checked]="true" value="0">Decide later</mat-radio-button>-->
                        <mat-radio-button (change)="radioServiceChange($event)" value="1">Recurring Service</mat-radio-button>
                        <mat-radio-button (change)="radioServiceChange($event)" value="2">One Time Service</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                <div class="row" *ngIf="wbOptions">
                  <div class="col-12">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="weeklySelection">
                     <!-- <mat-radio-button (change)="radioChange($event)" [checked]="true" value="0">Decide later</mat-radio-button>-->
                      <mat-radio-button (change)="radioChange($event)" value="1">Every Week</mat-radio-button>
                      <mat-radio-button (change)="radioChange($event)" value="2">Every Two Weeks</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>                
              </div>          	
        </div>                	
    </div>	
    <div class="modal-footer">	
      <!--<button type="button" class="btn btn-secondary" (click)="modal.close()">Return</button>-->	
      <button type="button" class="btn btn-primary" (click)="confirmSchedulePickup()" *ngIf="!wbQuestion && !wbOptions">Next</button>
      <button type="button" class="btn btn-primary" (click)="nextServiecSelection()" *ngIf="wbQuestion && !oneTimeservice">Next</button>      
      <!--<button type="button" class="btn btn-danger" (click)="noWB()" *ngIf="wbQuestion">No</button>-->
      <button type="button" class="btn btn-danger" (click)="backWeekly()" *ngIf="wbOptions">Back</button>      
      <button type="button" class="btn btn-primary" (click)="confirmWB()" *ngIf="wbOptions">Confirm</button>
      <button type="button" class="btn btn-primary" (click)="confirmOneTime()" *ngIf="oneTimeservice">Confirm</button>
    </div>	
</ng-template>	
<!-- Spinner -->	
<div *ngIf="spinner" class="spinner-background">	
    <div class="spinner">	
      <mat-spinner></mat-spinner>	
    </div>	
</div>
  