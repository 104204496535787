<mat-dialog-content class="mat-dialog-container-custom">
    <div class="container" id="subscribe-page">
        <mat-card *ngIf="new_subscriber">
            <div class="row">
                <div class="col-12">
                    <h4 class="trial-billing-text">One Month FREE</h4>                    
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="trial-billing-text">
                        Billing will start {{renewal_date}} for $9.99 per month. You can cancel anytime up to 24 hours before your trial ends. 
                    </p>
                </div>
            </div>
            <hr>
            <app-card></app-card>
            <hr>
            <div class="row">
                <div class="col-12">
                    <p class="billing-detail-text">
                        Great choice! By clicking Agree below, you agree to be charged $9.99 on {{renewal_date}} and every month until canceled. Cancel anytime up to 24 hours prior to the renewal date. The subscription is not refundable and cannot be transferred. Discounts apply to the bag price only. Click here for additional terms and conditions.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <input type="button" class="btn btn-primary" value="Agree" (click)="subscribe()">
                    <input type="button" class="btn btn-primary ml-2" value="Close" (click)="closeSubscribe()">
                </div>
            </div>
        </mat-card>
        <mat-card *ngIf="old_subscriber">
            <div class="row">
                <div class="col-12">
                    <p class="billing-detail-text">
                        Great choice! By clicking Agree below, you agree to be charged $9.99 on {{renewal_date}} and every month until canceled. Cancel anytime up to 24 hours prior to the renewal date. The subscription is not refundable and cannot be transferred. Discounts apply to the bag price only. Click here for additional terms and conditions.
                    </p>
                </div>
            </div>
            <hr>
            <app-card></app-card>
            <hr>            
            <div class="row">
                <div class="col-12">
                    <input type="button" class="btn btn-primary" value="Agree" (click)="subscribe()">
                    <input type="button" class="btn btn-primary ml-2" value="Close" (click)="closeSubscribe()">
                </div>
            </div>
        </mat-card>
    </div>
</mat-dialog-content>
<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
</div>