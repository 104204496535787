<!--<div fxLayout="column">
    <div class="    ">
        <app-navbar></app-navbar>
    </div> 
    <div fxLayout="row" fxFlex="100">
        <div class="my-content">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>
</div>-->
<div class="container">
    <div class="header">
        <app-navbar></app-navbar>
    </div>
    <div class="content">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
    <div class="footer">
        
    </div>
  </div>
  