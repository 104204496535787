<mat-dialog-content class="mat-dialog-container-custom">
  <div class="container" id="register-page">
    <div class="sec-title">
      <h3 class="text-left">Sign Up</h3>
    </div>
    <mat-card>      
      <form [formGroup]="registerForm">
      <div class="form-wrap">
        <h4>Personal Details</h4>
        <div class="form-inr">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class='required'>First Name</label>
                  <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class='required'>Last Name</label>
                  <input type="text" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                  <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                      <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class='required'>Email address</label>
                  <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted &&  f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Valid email address required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class='required'>Phone Number</label>
                  <input type="tel" formControlName="phone_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }" maxlength="10"  minlength="8" />
                  <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                      <div *ngIf="f.phone_number.errors.required">Phone number is required</div>
                      <div *ngIf="f.phone_number.errors?.maxlength || f.phone_number.errors?.minlength">Phone Number must be 8 to 10 digits long</div>                                        
                  </div>
                </div>
              </div>
            </div>                        
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class='required'>Password</label>
                  <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  maxlength="50"  minlength="8"  />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                      <div *ngIf="f.password.errors?.minlength">Password must be minimum 8 characters long</div>                                        
                      <div *ngIf="f.password.errors?.maxlength">Password must be maximum 50 characters long</div>                                        
                  </div>  
                </div>
              </div>
         
            <div class="col-sm-6">
              <label class='required'>Confirm Password</label>
              <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" maxlength="50"  minlength="8" />
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>                  
                  <div *ngIf="f.confirmPassword.errors?.minlength">Confirm password must be minimum 8 characters long</div>                                        
                  <div *ngIf="f.confirmPassword.errors?.maxlength">Confirm password must be maximum 50 characters long</div>                                                          
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Address</label>
                <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required">Address is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>City</label>
                <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Postal/Zip Code</label>
                <input type="text" formControlName="postal_code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.postal_code.errors }" />
                <div *ngIf="submitted && f.postal_code.errors" class="invalid-feedback">
                    <div *ngIf="f.postal_code.errors.required">Postal/Zip Code is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">   
              <label>Notes</label>
              <textarea formControlName="notes" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.notes.errors }" ></textarea>
              <div *ngIf="submitted && f.notes.errors" class="invalid-feedback">
                  <div *ngIf="f.notes.errors.required">Notes are required</div>
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" formControlName='agree' class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.agree.errors }"  required>I agree to the terms, conditions, and privacy policy.
                </label>                  
              </div>    
              <div *ngIf="submitted && f.agree.errors" class="invalid-feedback">
                <div *ngIf="f.agree.errors.required">You must agree to the Terms, Conditions, and Policy.</div>
              </div>            
            </div>
          </div>                              
        </div>
      </div>
      </form>            
    </mat-card>
    <div class="mt-2">      
      <div class="modal-button">
        <button class="btn btn-primary" color="primary" (click)="register()">
          Register
        </button>
      </div> <p>Already registered? <a (click)="login()" style="color: #61aeef;">Sign In</a></p>
    </div>    
  </div>    
</mat-dialog-content>

<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
