import { ElementRef,Component, OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BookingService } from 'src/app/core/services/booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import {FormControl,Validators,FormGroup,FormBuilder, FormArray} from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Router} from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { LoginComponent } from '../../login/login.component';
import { RegisterComponent } from '../../register/register.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  email: any;
  spinner: boolean;
  customer_id:any;
  submitted: boolean = false;

  constructor(private bookingService: BookingService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,    
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal) { }

  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }
  
  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
      });
  }
  resetPassword(){
    this.spinner = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.submitted = true;
    const body = {
      email: this.resetPasswordForm.controls['email'].value      
    };        
    this.userService.forgetPassword(body).subscribe(
      (res: any) => {
        if(res.status){
          this.spinner = false;
          this.snackBar.open('Email sent successfully!', 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });
          this.customer_id = res.result.id;
          localStorage.setItem('reset_password_customer_id', this.customer_id);
          // Reset Password Code sent, Now open the new password inputs. 
          this.dialog.open(UpdatePasswordComponent, {
            autoFocus: false,
          });
          this.dialogRef.close();

        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });  
        }    
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );  
  }
  login(){
    this.spinner = false;
    this.dialog.open(LoginComponent, {
      autoFocus: false,
    });
    this.dialogRef.close();
  }
  signup() {
    this.dialog.open(RegisterComponent, {
      autoFocus: false,
    });
    this.dialogRef.close();
  }
}
