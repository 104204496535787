import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// Material components
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

// Google Maps Address API
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

// Pages
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { SchedulesComponent } from './pages/schedules/schedules.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ManageScheduleComponent } from './pages/schedules/manage-schedule/manage-schedule.component';
import { RegisterComponent } from './register/register.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

// New pages
import { PlansComponent } from './pages/plans/plans.component';
import { JobComponent } from './pages/job/job.component';
import { CardComponent } from './pages/card/card.component';
import { OrderComponent } from './pages/order/order.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SubscribeComponent } from './pages/subscribe/subscribe.component';
import { ServiceareaComponent } from './pages/servicearea/servicearea.component';
import {MatRadioModule} from '@angular/material/radio';
import { FriscoComponent } from './pages/frisco/frisco.component';
import { DallasComponent } from './pages/dallas/dallas.component';
import { LittleelmComponent } from './pages/littleelm/littleelm.component';
import { AubreyComponent } from './pages/aubrey/aubrey.component';
import { CrossroadsComponent } from './pages/crossroads/crossroads.component';
import { DentonComponent } from './pages/denton/denton.component';
import { ArgyleComponent } from './pages/argyle/argyle.component';
import { FlowermoundComponent } from './pages/flowermound/flowermound.component';
import { FortworthComponent } from './pages/fortworth/fortworth.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';

import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    WalletComponent,
    SchedulesComponent,
    FooterComponent,
    NavbarComponent,
    ManageScheduleComponent,
    RegisterComponent,
    SuccessMessageComponent,
    ConfirmDialogComponent,
    PlansComponent,
    JobComponent,
    CardComponent,
    OrderComponent,
    PaymentComponent,
    SubscribeComponent,
    ServiceareaComponent,
    FriscoComponent,
    DallasComponent,
    LittleelmComponent,
    AubreyComponent,
    CrossroadsComponent,
    DentonComponent,
    ArgyleComponent,
    FlowermoundComponent,
    FortworthComponent,
    ResetPasswordComponent,
    UpdatePasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSidenavModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatDialogModule,
    MatMenuModule,
    MatGoogleMapsAutocompleteModule,
    MatRadioModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatIconModule,
    MatChipsModule,
    MatStepperModule,
    MatSlideToggleModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDAqogAiXuQbcPlweUPJ29kwN2BIVUMepU',
      libraries: ['places'],
    }),
  ],
  providers: [], 
  bootstrap: [AppComponent],
})
export class AppModule {}
