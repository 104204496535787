<div class="page-content">
  <div class="container  mt-5" id="account-page">
    <div class="sec-title">
      <h3 class="text-left">Dashboard</h3>
    </div>
    <mat-card>      
      <form [formGroup]="personalDetailForm">
        <div class="form-wrap">          
          <div class="form-inr">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4">                
                <div class="card h-65">
                  <div class="card-header">
                    Membership
                  </div>
                  <div class="card-body">
                    <div>Plan: <b>{{subscription.plan}}</b></div>                  
                    <div *ngIf="subscription.status && !subscription.suspended">Status: <b>Active</b></div>                  
                    <div *ngIf="subscription.status && !subscription.suspended"><b>Auto renewal on {{subscription.end}}</b></div>                  
                    <div *ngIf="subscription.status && subscription.suspended">Status: <b>Canceled</b></div>
                    <div *ngIf="subscription.status && subscription.suspended"><b>Ends on {{subscription.end}}</b></div>
                    <div *ngIf="!subscription.status && !subscription.status"><a [routerLink]="['/plans']">Want to upgrade to pro(crastinator) plan?</a></div>
                  </div> 
                  <div class="card-footer">
                    <button class="btn btn-danger mt-2" color="primary" (click)="cancelSubscription()" *ngIf="subscription.status && !subscription.suspended">
                      Cancel Subscription
                    </button>
                    <button mat-raised-button class="mt-2" color="primary" (click)="activateSubscription()" *ngIf="subscription.status && subscription.suspended">
                      Activate Subscription
                    </button>
                  </div>
                </div>   
                <div class="card h-30 my-2">
                  <div class="card-header">
                    Recurring Schedule
                  </div>
                  <div class="card-body">
                    <div class="row"> 
                      <div class="col-12">
                        Next Scheduled Service
                      </div>
                    </div>
                    <div class="row"> 
                      <div class="col-12">
                        {{next_scheduled}}
                      </div>
                    </div>                    
                  </div> 
                  <div class="card-footer">                    
                    <button mat-raised-button color="primary" *ngIf="autoOrderExists" (click)="changeSchedule()">Change</button>
                    <button class="btn btn-danger mx-1" *ngIf="autoOrderExists" color="primary" (click)="cancelSchedule()">
                      Cancel
                    </button>
                  </div>
                </div> 
                <div class="card h-5 my-2">
                  <div class="card-header">
                   Wallet Balance
                  </div>
                  <div class="card-body">
                    <div class="row"> 
                      <div class="col-12">                        
                          <blockquote class="blockquote breadcrumb-item">{{wallet_balance}}&#160;<a [routerLink]="['/wallet']">Details</a></blockquote>                                                    
                      </div>
                    </div>                    
                  </div>                   
                </div>                                
              </div>
              <div class="col-sm-12 col-md-6 col-lg-8" *ngIf="driver_selected">
                <div class="card">                  
                  <div class="card-header">Preferred Laundry Pro</div>
                  <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                          <div class="card">                          
                            <img class="card-img-top" src="{{publicURL}}{{driver.photo}}" alt="{{driver.name}}">                                          
                            <div class="card-footer text-center">                              
                                <small class="text-muted">{{driver.name}}</small>
                            </div>
                          </div>                                              
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-8">
                          <div class="row text-center">
                              <div class="col-12 text-center">
                                <div class="card">
                                  <div class="card-header">
                                    <h5>That's {{driver.name}}. Great Choice!</h5>
                                  </div>
                                  <div class="card-body">
                                    <p>Confirm or cancel below. Once you have chosen a preferred Pro only they will receive your pick up requests.</p>                                
                                  </div>
                                  <div class="card-footer text-center">                              
                                    <button class="btn btn-primary mx-2" (click)="confirmDriver(driver)">Confirm</button>
                                    <button class="btn btn-danger  mx-2" (click)="cancelDriver(driver)">Cancel</button>
                                  </div>
                                </div>                                
                              </div>
                          </div>                        
                        </div>
                      </div>                                                         
                  </div> 
                  <div class="card-footer">
                    <div class="text-justify">
                      <small class="text-muted">
                        Got a laundry pro you love or someone has recommended? Click the pro above or use their unique referral code to lock them in. After selecting a preferred partner only they will be able to see your orders.
                      </small>
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-8" *ngIf="!driver_selected">
                <div class="card">        
                  <div class="card-header px-2">Preferred Laundry Pro</div>          
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card">                          
                          <img class="card-img-top" *ngIf="selected_driver" src="{{publicURL}}{{selected_driver.photo}}" alt="{{selected_driver.name}}">                                                                    
                          <div class="card-body card-body-no-image" *ngIf="!selected_driver">
                            <div class="container d-flex h-100">
                              <div class="row justify-content-center align-self-center">
                                <h5>No Preference</h5>
                              </div>
                            </div>                            
                          </div>
                          <div class="card-footer text-center" *ngIf="selected_driver">                              
                              <div>
                                {{selected_driver.name}}
                              </div>                              
                              <div>
                                <button class="btn btn-danger" (click)="removePreferredParter()"><i class="fa fa-window-close" aria-hidden="true"></i> Change</button>
                              </div>
                          </div>
                        </div>                                              
                      </div>
                      <div class="col-sm-12 col-md-8 col-lg-8">
                         <div class="row">
                            <div class="col-12">
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Enter Referral Code" name="code" (input)= "code=$event.target.value">
                                <div class="input-group-append">
                                  <button class="btn btn-success" type="submit" (click)="addPreferredParterCode()">Add</button>  
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="row text-center">                          
                          <div class="col-12">
                            <h5>Previous Partners</h5>
                            <div id="custCarousel" class="carousel slide" data-ride="carousel" align="center">
                              <!-- slides -->
                              <div class="carousel-inner">
                                  <div [ngClass]="(i==0)?'carousel-item active':'carousel-item'" *ngFor="let driver of previous_preferred_drivers; let i = index">
                                    <div class="row">
                                      <div class="col-4" *ngFor="let d of driver;" (click)="driverSelected(d)">                            
                                          <div class="card">
                                            <img class="card-img-top" *ngIf="d.photo" src="{{publicURL}}{{d.photo}}" alt="{{d.name}}">                                                                                                                
                                            <img class="card-img-top" *ngIf="!d.photo" src="https://picsum.photos/200/300?random={{getRandomNumber()}}" alt="{{d.name}}">                                                                                                                
                                            <div class="card-footer text-center px-0">
                                                {{d.name}}
                                            </div>
                                          </div>                                                                                                                                        
                                      </div>
                                    </div>                                     
                                  </div>                                  
                              </div> <!-- Left right --> <a class="carousel-control-prev" href="#custCarousel" data-slide="prev"> <span class="carousel-control-prev-icon"></span> </a> <a class="carousel-control-next" href="#custCarousel" data-slide="next"> <span class="carousel-control-next-icon"></span> </a> <!-- Thumbnails -->                              
                            </div>

                            <!--
                            <div id="slider">
                              <div id="carouselPreferredDrivers" class="carousel slide" data-ride="carousel" *ngIf="previous_preferred_drivers">
                                <div class="carousel-inner">
                                  <div [ngClass]="(i==0)?'carousel-item active':'carousel-item'" *ngFor="let driver of previous_preferred_drivers; let i = index">
                                    <div class="card-deck">
                                      <div class="card" *ngFor="let d of driver;" (click)="driverSelected(d)">
                                          <img class="card-img-top card-img-top-previous-drivers" src="{{publicURL}}{{d.photo}}" alt="{{d.name}}">                                          
                                          <div class="card-footer">
                                              <small class="text-muted">{{d.name}}</small>
                                          </div>
                                      </div>                                      
                                    </div>
                                  </div>                                  
                                </div>
                                <a class="carousel-control-prev" href="#carouselPreferredDrivers" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselPreferredDrivers" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                            </div>
                            -->

                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div class="card-footer">
                    <div class="text-justify">
                      <small class="text-muted">
                        Got a laundry pro you love or someone has recommended? Click the pro above or use their unique referral code to lock them in. After selecting a preferred partner only they will be able to see your orders.
                      </small>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card>      
    <mat-card class="mt-3">      
      <form [formGroup]="personalDetailForm">
      <div class="form-wrap">
        <h4>Personal Details</h4>
        <div class="form-inr">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': f.name.errors }" />
                  <div *ngIf="f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': f.last_name.errors }" />
                  <div *ngIf="f.last_name.errors" class="invalid-feedback">
                      <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': f.email.errors }" readonly />
                  <div *ngIf="f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Phone Number</label>
                  <input type="text" formControlName="phone_number" class="form-control" [ngClass]="{ 'is-invalid': f.phone_number.errors }" />
                  <div *ngIf="f.phone_number.errors" class="invalid-feedback">
                      <div *ngIf="f.phone_number.errors.required">Phone number is required</div>
                  </div>
                </div>
              </div>
            </div>                        
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Enter New Password or Leave blank</label>
                  <input type="text" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': f.password.errors }" />
                  <div *ngIf="f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>  
                </div>
              </div>
         
            <div class="col-sm-6">
              <label>Confirm New Password or Leave blank</label>
              <input type="text" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': f.confirmPassword.errors }" />
              <div *ngIf="f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
              </div>  
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Address</label>
                <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': f.address.errors }" />
                <div *ngIf="f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required">Address is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>City</label>
                <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': f.city.errors }" />
                <div *ngIf="f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Postal/Zip Code</label>
                <input type="text" formControlName="postal_code" class="form-control" [ngClass]="{ 'is-invalid': f.postal_code.errors }" />
                <div *ngIf="f.postal_code.errors" class="invalid-feedback">
                    <div *ngIf="f.postal_code.errors.required">Postal/Zip Code is required</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-sm-6">   
              <label>Notes</label>
              <textarea formControlName="notes" class="form-control" [ngClass]="{ 'is-invalid': f.notes.errors }" ></textarea>
              <div *ngIf="f.notes.errors" class="invalid-feedback">
                  <div *ngIf="f.notes.errors.required">Notes are required</div>
              </div>  
            </div>
          </div>
          -->
          <div class="modal-button">
            <button mat-raised-button color="primary" (click)="updateUser()">
              Save Changes
            </button>
          </div>
        </div>
      </div>
      </form>      
      <form [formGroup]="cardDetailForm">
        <div class="form-wrap mt-20" >          
          <h4>Card Details</h4>       
          <div class="container d-flex h-50">
            <div class="row justify-content-center align-self-center" *ngIf="!editCard">          
              <img src="{{icon}}" class="img-fluid" alt="CC" style="max-width:50px;max-height:50px" /> <span style="align-self: center;"> {{c.ccnumber.value}} </span>
              <button mat-raised-button color="primary" class="ml-2" (click)="modifyCard()" style="max-height:40px">
                Edit Card
              </button>
            </div>   
          </div>
          <div class="form-inr" *ngIf="editCard">
            <div class="row">
              <div class="col-sm-6">
                <label>Card Number</label>
                <input type="tel" formControlName="ccnumber" class="form-control hpsNumberField" [ngClass]="{ 'is-invalid': c.ccnumber.errors }"  maxlength="19"  minlength="13" />                
                <div *ngIf="c.ccnumber.errors" class="invalid-feedback">
                    <div *ngIf="c.ccnumber.errors.required">Card Number is required</div>
                    <div *ngIf="c.ccnumber.errors?.maxlength || c.ccnumber.errors?.minlength">Card Number must be 13 to 19 digits long</div>                                        
                </div>                                
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">             
                <label>Expiration Month</label>
                <select formControlName="expirationMonth" class="form-control" [ngClass]="{ 'is-invalid': c.expirationMonth.errors }">
                  <option *ngFor="let month of months" [value]="month.value">
                    {{month.viewValue}}
                  </option>
                </select>
                <div *ngIf="c.expirationMonth.errors" class="invalid-feedback">
                    <div *ngIf="c.expirationMonth.errors.required">Expiration month is required</div>
                </div>                 
              </div>
              <div class="col-sm-4">
                <label>Expiration Year</label>
                <select formControlName="expirationYear" class="form-control" [ngClass]="{ 'is-invalid': c.expirationYear.errors }">
                  <option *ngFor="let year of years" [value]="year.value">
                    {{year.viewValue}}
                  </option>
                </select>
                <div *ngIf="c.expirationYear.errors" class="invalid-feedback">
                    <div *ngIf="c.expirationYear.errors.required">Expiration year is required</div>                    
                </div>                 
              </div>
              <div class="col-sm-4">
                <label>Security Code</label>
                <input type="tel" formControlName="cvv" class="form-control hpsNumberField" [ngClass]="{ 'is-invalid': c.cvv.errors }" maxlength="4" minlength="3" />                
                <div *ngIf="c.cvv.errors" class="invalid-feedback">
                    <div *ngIf="c.cvv.errors.required">Security Code is required</div>
                    <div *ngIf="c.cvv.errors?.maxlength || c.cvv.errors?.minlength">Security Code must be 3 to 4 digts long</div>                    
                </div>                
              </div>
            </div>
          </div>
          <h4 class="mt-5"  *ngIf="editCard">Billing Information</h4>
          <div class="form-inr" *ngIf="editCard">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" (change)="onCheckboxChange($event)" formControlName='checkAddress' class="form-check-input" value="">Use Residential Address
                  </label>
                </div>                
              </div>
            </div>            
            <div class="row mt-3">
              <div class="col-sm-6">
                <label>Billing Address 1</label>
                <input type="text" formControlName="addressOne" class="form-control" [ngClass]="{ 'is-invalid': c.addressOne.errors }" maxlength="140" minlength="2" />                
                <div *ngIf="c.addressOne.errors" class="invalid-feedback">
                    <div *ngIf="c.addressOne.errors.required">Billing Address 1 is required</div>
                </div>                                
              </div>
              <div class="col-sm-6">
                <label>Billing Address 2</label>
                <input type="text" formControlName="addressTwo" class="form-control" [ngClass]="{ 'is-invalid': c.addressTwo.errors }" maxlength="140" minlength    ="2" />                
                <div *ngIf="c.addressTwo.errors" class="invalid-feedback">
                    <div *ngIf="c.addressTwo.errors.required">Billing Address 2 is required</div>
                </div>                                
              </div>              
              <div class="col-sm-6">
                <label>City</label>
                <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': c.city.errors }" />
                <div *ngIf="c.city.errors" class="invalid-feedback">
                    <div *ngIf="c.city.errors.required">City is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <label>State</label>
                <input type="text" formControlName="state" class="form-control" [ngClass]="{ 'is-invalid': c.state.errors }" />
                <div *ngIf="c.state.errors" class="invalid-feedback">
                    <div *ngIf="c.state.errors.required">State is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <label>Postal/Zip Code</label>
                <input type="text" formControlName="postal_code" class="form-control" [ngClass]="{ 'is-invalid': c.postal_code.errors }" />
                <div *ngIf="c.postal_code.errors" class="invalid-feedback">
                    <div *ngIf="c.postal_code.errors.required">Postal/Zip Code is required</div>
                </div>
              </div>
            </div>
            <div class="modal-button">
              <button mat-raised-button color="primary" (click)="updateCard()">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </form>
      </mat-card>
    </div>

    <div class="order-cards">
      <div id="account-page" class="container ">
        <ng-container *ngIf="trips">
          <mat-card>
            <h3>Orders</h3>
            <span *ngIf="trips.length === 0"> &nbsp; No order found for this user.</span
                >
            <div class="row">
              <div class="col-sm-6" id="card-wrap" *ngFor="let item of trips">                
                  <div class="card">
                    <div class="card-header">
                      <a [routerLink]="['/job', item.book_id]">Order # {{item.book_id}} </a>                      
                    </div>
                    <div class="card-body" [routerLink]="['/job', item.book_id]">
                      <div class="name-txt">{{ item.customer_id }}</div>
                      <div class="name-txt">{{ item.driver_name }}</div>
                      <div class="text-mute">
                        <p>Pick up Date & Time</p>
                        <span> {{ item.today_date }} {{ item.pick_up_time }} </span>
                      </div>
                      <div class="text-mute">
                        <p>Pick up Address</p>
                        <span *ngIf="item.pick_up !== 'NoDrop'">
                            {{ item.pick_up }} <!-- this are not used right now {{ item.drop_location }}  {{ item.drop_time }} -->
                        </span>
                      </div>                    
                    </div> 
                    <div class="card-footer">
                      <div class="status">
                        <div class="row">
                          <div class="col-sm-12">
                            <span class="status-btn  m-1 {{ getStatus(item.status) }}">{{ getStatus(item.status) }}</span>
                            <button class="btn btn-danger m-1" *ngIf="item.status === '1' || item.status === '2' || item.status === '2.1'" (click)="cancelOrderConfirmation(item.book_id)"><i class="fas fa-window-close"></i> Cancel Order</button>                                                                        
                          </div>                          
                        </div>
                      </div>              
                    </div>
                  </div>
              </div>              
            </div>

            <div class="table-wrapper">
              <!-- <table>
                <tr>
                  <th>Date</th>
                  <th>Location</th>
                  <th>Pickup Time</th>
                  <th>Partner Name</th>
                  <th>Return Time</th>
                  <th>Status</th>
                </tr>
                <span *ngIf="trips.length === 0">
                  <br />
                  &nbsp; No trips found for this user.</span
                >

                <tr *ngFor="let item of trips">
                  <td>{{ item.today_date }}</td>
                  <td>
                    <span *ngIf="item.drop_location !== 'NoDrop'">
                      {{ item.drop_location }}
                    </span>
                  </td>
                  <td>{{ item.pick_up_time }}</td>
                  <td>{{ item.driver_name }} {{ item.driver_lname }}</td>
                  <td>{{ item.drop_time }}</td>
                  <th>{{ getStatus(item.status) }}</th>
                </tr>
              </table> -->
            </div>
          </mat-card>
        </ng-container>
      </div>
    </div>
</div>

<!-- Weekly Cancel Popup -->
<ng-template #modalCancelWeekly let-modal>
  <div class="modal-header confirm-modal-header">
    <h4 class="modal-title confirm-modal-heading">Cancel Weekly Schedule</h4>
    <button type="button" class="close  text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Are you sure you want to cancel your <span class="text-primary">"Weekly"</span> pickup?</strong></p>        
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="confirmCancelSchedule()">Confirm</button>
    <button type="button" class="btn btn-primary" (click)="reschedule()">Reschedule</button>
  </div>
</ng-template>
<!-- Weekly Cancel Popup -->

<!-- Weekly Shedule Popup -->
<ng-template #modalWeeklySchedule let-modal>
  <div class="modal-header confirm-modal-header">	
    <h4 class="modal-title confirm-modal-heading" id="modal-basic-title">Schedule Your Weekly Pickup Now!</h4>	
    <button type="button" class="close close-icon text-white" aria-label="Close" (click)="modal.dismiss()">	
      <span aria-hidden="true">×</span>	
    </button>	
  </div>	
  <div class="modal-body">	
      <div class="row">	
          <div class="col-12 my-2">
            <div class="row">
              <div class="col-12">
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button (change)="radioChange($event)" [checked]="weeklyBool" value="1">Weekly</mat-radio-button>
                  <mat-radio-button (change)="radioChange($event)"  [checked]="BiweeklyBool" value="2">Biweekly</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="col-12 my-2">	
              <div class="row">
                <div class="col-3">
                  <label for="bookDate">Date</label>	
                </div>
                <div class="col-9">
                  <input type="date" class="mx-2" id="bookDate"  [(ngModel)]="bookDate" name="bookDate" (change)="dateChanged(bookDate)">	
                </div>
              </div>
          </div>	
          <div class="col-12 my-2">
            <div class="row">
              <div class="col-3">
                <label for="bookTime">Time</label>	                 	
              </div>
              <div class="col-9">
                <select name="timeFrames"  class="mx-2" [(ngModel)]="timeFrames" (change)="timeframeChange()">	
                    <option [value]="0">Select Pickup Time</option>	
                    <option *ngFor="let pickup_time of pickup_time_frames;" [value]="pickup_time.id" [disabled]="pickup_time.display">	
                      {{pickup_time.display_text}}	
                    </option>	
                </select>          
              </div>      	
            </div>
          </div>  
          <div class="col-12 my-2">	
            <div class="row">
              <div class="col-3">
                <label for="smallBag">Small Bag</label>	
              </div>
              <div class="col-9">
                <input type="number" min="0" class="mx-2" id="smallBag" name="smallBag" [(ngModel)]="smallBag">
              </div>
            </div>
          </div>	          	
          <div class="col-12 my-2">	
            <div class="row">
              <div class="col-3">
                <label for="largeBag">Large Bag</label>	
              </div>
              <div class="col-9">
                <input type="number" min="0" class="mx-2" id="largeBag" name="largeBag" [(ngModel)]="largeBag">
              </div>
            </div>
          </div>	          	
          <div class="col-12 my-2">	
            <div class="row">
              <div class="col-3">
                <label for="tip">Tip</label>	
              </div>
              <div class="col-9">
                <input type="number" min="0" class="mx-2" id="tip" name="tip" [(ngModel)]="tip">
              </div>
            </div>
          </div>	          	
          <div class="col-12 my-2">	
            <div class="row">
              <div class="col-3">
                <label for="instructions">Delivery Instructions</label>	
              </div>
              <div class="col-9">
                <textarea instructions class="mx-2" [(ngModel)]="instructions" placeholder="Delivery Instructions: i.e. Apt #"></textarea>
              </div>
            </div>
          </div>	
      </div>                	
  </div>	
  <div class="modal-footer">	
    <!--<button type="button" class="btn btn-secondary" (click)="modal.close()">Return</button>-->	
    <button type="button" class="btn btn-primary" (click)="confirmScheduleChanged()">Confirm</button>	
  </div>
</ng-template>
<!-- Weekly Schedule Popup -->


<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
