import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { BookingService } from 'src/app/core/services/booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router,ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {

  address:any;
  apartment_number:any;
  small_bag:any=0;
  large_bag:any=0;  
  small_bag_array:any;
  large_bag_array:any;
  booking_id: any;
  driver_name: any;
  notes:any;
  instructions:any;
  status: any;
  short_status: any;
  status_color:any;
  status_background_color:any;
  statuses:any;
  message: any;
  stepper:any;
  stepper_index:any;
  job_cancelled:boolean=false;
  currentRouter = this.router.url;
  spinner: boolean;  
  closeResult: string;

  constructor(
    private bookingService: BookingService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private router: Router,
    private dialog: MatDialog,
    private _Activatedroute:ActivatedRoute
  ) { }
  
  @ViewChild('stepper', {static: false}) private myStepper: MatStepper;

  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => { 
      this.booking_id = params.get('id'); 
    });     
    this.getBookingDetail();
  }
  getBookingDetail(){
    const body = {
      booking_id: this.booking_id
      };
    this.spinner = true;
    this.bookingService.getBookingDetail(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if (res.status) {          
            this.address = res.job.pick_up;
            this.apartment_number = res.job.apartment_number;
            this.small_bag = res.job.small_bag;
            this.large_bag = res.job.large_bag;
            this.small_bag_array = new Array(this.small_bag);
            this.large_bag_array = new Array(this.large_bag);
            this.booking_id = res.job.id;            
            this.notes = res.job.customer_notes;
            this.instructions = res.job.delivery_instructions;
            this.status = res.statuses.find(i => i.number === res.job.status).status;

            this.status_color = res.statuses.find(i => i.number === res.job.status).text_color;
            this.status_background_color = res.statuses.find(i => i.number === res.job.status).background_color;
            this.short_status = res.statuses.find(i => i.number === res.job.status).short_status;            
            this.stepper_index = res.statuses.find(i => i.number === res.job.status).id-1;            
            this.statuses = res.job_statuses;            
            switch(res.job.status){
              case "5":
              case "9":
              case "10":
              case "11":
              case "12":
                this.job_cancelled = true;
                break;
              default:
                break;
            }            
            this.driver_name = res.driver.name + ' ' +res.driver.last_name; 
        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 5000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {      
        this.spinner = false;  
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  }
  contactSupport(){
    //window.location.href = "mailto:address@dmail.com";
      this.prepareEmail('support@laundryod.com', 'Houston We Have A Problem', '');
  }
  prepareEmail(to, subject, bodyText) {
    var form = document.createElement('form');
    
    //Set the form attributes 
    form.setAttribute('method', 'post');
    form.setAttribute('enctype', 'text/plain');
    form.setAttribute('action', 'mailto:' + encodeURIComponent(to) + '?Subject=' + encodeURIComponent(subject) + '&Body=' + encodeURIComponent(bodyText ? bodyText : ' ') );
    form.setAttribute('style', 'display:none');
    
    //Append the form to the body
    document.body.appendChild(form);
   
    //Submit the form
    form.submit();
    
    //Clean up
    document.body.removeChild(form);
  }
  callDriver(){
    const body = {
      booking_id: this.booking_id,
      type:1
      };
      this.spinner = true;
    this.bookingService.setupCall(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if (res.status) {       
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });              
        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {      
        this.spinner = false;  
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );    
  }
  cancelOrder(){
    const body = {
      booking_id: this.booking_id,
      mode:"schedule_customer_cancel"
      };
      this.spinner = true;
    this.bookingService.cancelOrder(body).subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner = false;  
          this.job_cancelled = true;     
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                        
          this.router.navigate(['/order']);
        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {        
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );    
  }
  ngAfterContentInit() {
    if(this.stepper_index <= this.myStepper.steps.length){
     this.myStepper.selectedIndex = this.stepper_index;   
    }     
  }
  
  ngAfterViewInit() {
    if(this.stepper_index <= this.myStepper.steps.length){
      this.myStepper.selectedIndex = this.stepper_index;                    
    }    
  }
  stepperSelectionChange(e){
    console.log('stepper changed');
    
  }
  messageDriver(){
    const body = {
      booking_id: this.booking_id,
      type:1,
      message: this.message
      };
      this.spinner = true;
    this.bookingService.sendMessage(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if (res.status) {       
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });              
        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {      
        this.spinner = false;  
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );    
  }  

  cancelOrderConfirmation() {
    
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel Order',
        message: 'Are you sure, you want to cancel the order?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {        
        this.cancelOrder();
      }
    });    
    
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}