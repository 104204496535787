<div class="page-content" style="background-color: #fff;">
    <section class="pricing py-5">
        <div class="container">
        <div class="row">
            <!-- Free Tier -->
            <div class="col-lg-6">
            <div class="card mb-5 mb-lg-0">                
                <div class="card-body">
                <h5 class="card-title text-muted text-uppercase text-center">Pro (Crastinator) plan</h5>                
                <h6 class="card-price text-center">$9.99<span class="period">/month</span></h6>
                <hr>
                <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>5% Discount</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Unlimited FREE Delivery</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Satisfaction Guarantee</li>                    
                </ul>
                <div *ngIf="new_subscriber" class="btn btn-block btn-primary text-uppercase" (click)="showSubscription()">Try FREE for one Month</div>
                <div *ngIf="old_subscriber" class="btn btn-block btn-primary text-uppercase" (click)="showSubscription()">Subscribe Now!</div>
                <span *ngIf="active_subscriber">Thank you for subscribing to Pro (Crastinator) plan</span>
                <span *ngIf="trial_subscriber">Thank you for subscribing to Trialing Pro (Crastinator) plan</span>
                <button *ngIf="active_subscriber || trial_subscriber" class="btn btn-primary" class="btn btn-block btn-primary text-uppercase" (click)="schedule()">Schedule Your Pick UP Now!</button>
                </div>
            </div>
            </div>
            <!-- Plus Tier -->
            <div class="col-lg-6">
            <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                <h5 class="card-title text-muted text-uppercase text-center">Pay As you Go</h5>
                <h6 class="card-price text-center">$0<span class="period">/month</span></h6>
                <hr>
                <ul class="fa-ul">
                    <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>5% Discount</li>                                
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>$4.99 Delivery Charges</li>                    
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Satisfaction Guarantee</li>                    
                </ul>
                <button class="btn btn-primary" class="btn btn-block btn-primary text-uppercase" (click)="schedule()">Schedule Your Pick UP Now!</button>
                </div>
            </div>
            </div>        
        </div>
        </div>
    </section>
</div>