import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public httpOptions: any;

  constructor(private http: HttpClient) {}

  login(body: { email: any; password: any; type: string }) {
    
    return this.http.post<any>(`${environment.api}/login`, body);
  }

  register(body: {
    name: any;
    last_name: any;
    phone_number: any;
    email: any;
    password: any;
    repeat_password: any;
    address:any;
    city:any;
    postal_code:any;
    notes:any;
    /*
    card_number: any;
    card_month_expiry: any;
    card_year_expiry: any;
    card_cvv: any;
    card_postal_code:any;
    card_address1:any;
    card_address2:any;
    card_city:any;
    card_state:any;
    */
  }) {
    return this.http.post<any>(`${environment.api}/register`, body);
  }
  registerSmall(body: {
    name: any;
    last_name: any;
    phone_number: any;
    email: any;
    password: any;
    repeat_password: any;    
  }) {
    return this.http.post<any>(`${environment.api}/register`, body);
  }
}
