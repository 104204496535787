import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Validators,FormBuilder} from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TileStyler } from '@angular/material/grid-list/tile-styler';

@Component({
  selector: 'app-servicearea',
  templateUrl: './servicearea.component.html',
  styleUrls: ['./servicearea.component.css']
})
export class ServiceareaComponent implements OnInit {

  showServiceDialog: boolean = false;
  checking: boolean = false;
  serviceareafound:boolean = false;
  isRegister:boolean = false;
  step1:boolean = false;
  step2:boolean = false;
  showclose:boolean = false;
  isLogin:boolean = false;
  email:any; 
  spinner: boolean = false;
  submitted: boolean = false;
  registerForm: any;
  stepnumber:number = 1;

  constructor(private snackBar: MatSnackBar,
              private authService: AuthService,
              private router: Router,
              private parentDilogRef: MatDialog,
              private formBuilder: FormBuilder,       
              private userService: UserService) { }
  get f() { return this.registerForm.controls; }
  ngOnInit(): void {
    this.setServiceDialogStatus();
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.maxLength(10) , Validators.minLength(8)]],
      postal_code: [''],
      address: [''],
      city: [''],
      password: ['', [Validators.required, Validators.maxLength(50) , Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(50) , Validators.minLength(8)]],
      notes: [''],
      agree:false,
    });
  }
  setDelay(){
    var delayInMilliseconds = 3000;      
    setTimeout(() => {        
      console.log('waiting...');
    }, delayInMilliseconds);              
  }
  isLoggedIn() {
    if ('user_id' in sessionStorage) {
      return true;
    } else {
      return false;
    }
  }
  setServiceDialogStatus(){
    if(!localStorage.getItem('service_coverage')){      
      this.checking = false;   
      setTimeout(() => {      
      document.getElementById('divRegister').style.display = 'block';
      this.isRegister = true;
      this.showclose = true; 
    }, 100);                        
    }else{
    this.checking = true;                
    setTimeout(() => {
      document.getElementById('divChecking').style.display = 'block';
      setTimeout(() => {
        document.getElementById('divChecking').style.display = 'none';
        if(localStorage.getItem('service_coverage')){                               
            if(localStorage.getItem('service_coverage') === '1'){                      
              this.serviceareafound = true;            
              document.getElementById('divFound').style.display = 'block';
              document.getElementById('divChecking').style.display = 'none';
                setTimeout(() => {                  
                  this.showclose = true; 
                  this.showServiceDialog= true;  
                  if ('user_id' in sessionStorage) {       
                    document.getElementById('divFound').style.display = 'none';
                    document.getElementById('divChecking').style.display = 'none';
                    document.getElementById('divRegister').style.display = 'block';
                    this.isLogin = true;
                    document.getElementById("cardDashboard").classList.remove("card-disabled");
                    document.getElementById("cardRegistration").classList.add("card-disabled");
                    document.getElementById("divDashboard").classList.remove("dashboard-disabled");            
                    this.stepnumber = 2;                                                                   
                  }else{
                    document.getElementById('divFound').style.display = 'none';
                    document.getElementById('divChecking').style.display = 'none';
                    document.getElementById('divRegister').style.display = 'block';
                    this.isRegister = true;
                  }
              }, 1000);                        
            }else{                
              document.getElementById('divNotFound').style.display = 'block';
              document.getElementById('divChecking').style.display = 'none';
              this.serviceareafound = false;        
              this.showServiceDialog= true;  
              this.showclose = true;
            }        
        }else{
          //this.showServiceDialog= true;
          //this.showclose = true;
          //document.getElementById('divChecking').style.display = 'none';
          //document.getElementById('divRegister').style.display = 'block';
        } 
      }, 1000);     
    }, 100);         
  }
  }
  saveEmail(){
    if(!this.email){
      this.snackBar.open('Enter your email address please.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
    }
    const body = {
      email: this.email,
      address: localStorage.getItem('address')
    };
    this.userService.addServiceAreaRequest(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){                    
          document.getElementById('divNotFound').style.display = 'none';          
          document.getElementById('divAddCoverage').style.display = 'block';                                       
          setTimeout(() => {
            this.parentDilogRef.closeAll();      
               }, 2000);          
        }else{
          
        }                       
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );     
  }
  close(){
    this.parentDilogRef.closeAll();      
  }
  register() {
    this.submitted = true;
    if(this.registerForm.value.password !== this.registerForm.value.confirmPassword){
      this.snackBar.open('Error: Password mismatch!', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if(!Number(this.registerForm.value.phone_number)){
      this.snackBar.open('Error: Invalid phone number!', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if(!this.registerForm.value.agree){
      this.snackBar.open('Error: You must agree to the terms, conditions, and Policy.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if (this.registerForm.valid) {
      this.spinner = true;
      const body = {
        name: this.registerForm.value.name,
        last_name: this.registerForm.value.last_name,
        phone_number: this.registerForm.value.phone_number,
        email: this.registerForm.value.email,
        password: this.registerForm.value.password,
        repeat_password: this.registerForm.value.confirmPassword,
      };
      this.authService.registerSmall(body).subscribe(
        (_res: any) => {          
          if(_res.status){
            this.snackBar.open(_res.message, 'Dismiss', {
              duration: 3000,
              panelClass: ['notif-success-green'],
              verticalPosition: 'top',
            });
            this.spinner = false;            
            document.getElementById("cardDashboard").classList.remove("card-disabled");
            document.getElementById("cardRegistration").classList.add("card-disabled");
            document.getElementById("divDashboard").classList.remove("dashboard-disabled");            
            this.stepnumber += 1;
            this.loginRegisteredUser();
          }else{
            this.spinner = false;          
            this.snackBar.open(_res.message, 'Dismiss', {
              duration: 3000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            });
            this.submitted = false;  
          }
        },
        (_error: any) => {
          this.spinner = false;          
          this.snackBar.open(_error.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
          this.submitted = false;
        }
      );
    } else {      
      this.snackBar.open('Error: All fields are required.', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
    }
  }
  dashboard(){    
    this.router.navigate(['/account']);    
    this.parentDilogRef.closeAll();
  }
  schedule(){
    this.router.navigate(['/order']);
    this.parentDilogRef.closeAll();
  }
  loginRegisteredUser(){
    this.spinner = true;
    const body = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      type: 'customer',
    };
    this.authService.login(body).subscribe(
      (res: any) => {
        if(res.status){
          sessionStorage.setItem('user_id', res.customer.id);
          sessionStorage.setItem('customer', res.customer);
          localStorage.setItem('user_id', res.customer.id);
          localStorage.setItem('customer', res.customer);
          localStorage.setItem('subscription', res.subscription.original.data['subscription_status']);
          sessionStorage.setItem('notes', res.customer.notes ? res.customer.notes : '');
   
        }else{
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });        
        }        
      },
      (_error: any) => {        
        this.spinner = false;
        this.snackBar.open(_error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });        
      }
    );
  }
  goToLink(url: string){
    window.open(url, "_blank");
  }
}
