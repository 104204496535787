import { Component, OnInit,ViewChild,NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BookingService } from 'src/app/core/services/booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import {FormControl,Validators,FormGroup,FormBuilder} from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatRadioChange } from '@angular/material/radio';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



interface Month {
  value: string;
  viewValue: string;
}

interface Year {
  value: string;
  viewValue: string;
}

enum Subscription{
  Unsub = 'unsub',
  Canceled = 'canceled',
  Ended = 'ended',
  Active = 'active',
  Trialing = 'trialing',
  Plan = 'Pro Crastinator($9.99/month)',
  Basic = "Pay as you Go"
}
interface StripeSubscription{  
  status:boolean;
  plan:string;
  start: string;
  end: string;
  suspended: boolean;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})


export class ProfileComponent implements OnInit {
  name: any;
  email: any;
  password: any;
  confirmPassword: any;
  ccnumber: any;
  expirationMonth: any;
  expirationYear: any;
  cvv: any;
  trips: any;
  notes: any;
  PAT_NAME: any;
  spinner: boolean;
  personalDetailForm: FormGroup;
  cardDetailForm: FormGroup;
  editCard: boolean = false;
  icon: string;
  subscription:StripeSubscription = {end:'',start:'',plan:'',status:false,suspended:false};
  preferred_driver_id: any = 0;
  previous_preferred_drivers: any;
  publicURL: any = environment.public;
  driver_selected: boolean=false;
  driver:any;
  selected_driver:any;
  code:any;
  next_scheduled:any="None";  
  showModalCancel:boolean = false;
  timeFrames:any = '1';  
  timeFrameSelected:any;  
  pickup_time_frames:any;
  userAdjustedTimeFrame:any;
  hours:any;
  minutes:any;
  pickup_date:any;
  server_date_time:any;
  server_date:any;
  server_time:any;
  bookDate:any;
  smallBag:number=0;
  largeBag:number=0;
  tip:number=0;
  instructions:string;
  weeklySelected:any="1";
  weeklyBool:boolean = true;
  BiweeklyBool:boolean = false;
  autoOrderExists:boolean = false;
  wallet_balance: any='$0';
  useWalletBalance:boolean = false;

  months: Month[] = [
    {value: '1', viewValue: 'January'},
    {value: '2', viewValue: 'February'},
    {value: '3', viewValue: 'March'},
    {value: '4', viewValue: 'April'},
    {value: '5', viewValue: 'May'},
    {value: '6', viewValue: 'June'},
    {value: '7', viewValue: 'July'},
    {value: '8', viewValue: 'August'},
    {value: '9', viewValue: 'September'},
    {value: '10', viewValue: 'October'},
    {value: '11', viewValue: 'November'},
    {value: '12', viewValue: 'December'}
  ];
  
  years: Year[] = [
    {value: '2021', viewValue: '2021'},
    {value: '2022', viewValue: '2022'},
    {value: '2023', viewValue: '2023'},
    {value: '2024', viewValue: '2024'},
    {value: '2025', viewValue: '2025'},
    {value: '2026', viewValue: '2026'},
    {value: '2027', viewValue: '2027'},
    {value: '2028', viewValue: '2028'},
    {value: '2029', viewValue: '2029'},
    {value: '2030', viewValue: '2030'},
    {value: '2031', viewValue: '2031'},
    {value: '2032', viewValue: '2032'},
    {value: '2033', viewValue: '2033'},
    {value: '2034', viewValue: '2034'},
    {value: '2035', viewValue: '2035'},
    {value: '2036', viewValue: '2036'},
    {value: '2037', viewValue: '2037'},
    {value: '2038', viewValue: '2038'},
    {value: '2039', viewValue: '2039'},
    {value: '2040', viewValue: '2040'},
    {value: '2041', viewValue: '2041'},
    {value: '2042', viewValue: '2042'},
    {value: '2043', viewValue: '2043'},
    {value: '2044', viewValue: '2044'},
    {value: '2045', viewValue: '2045'},
    {value: '2046', viewValue: '2046'},
    {value: '2047', viewValue: '2047'},
    {value: '2048', viewValue: '2048'},
    {value: '2049', viewValue: '2049'},
    {value: '2050', viewValue: '2050'},
    {value: '2051', viewValue: '2051'},
    {value: '2052', viewValue: '2052'},
  ];
  
  constructor(
    private bookingService: BookingService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  // convenience getter for easy access to form fields
  get f() { return this.personalDetailForm.controls; }
  get c() { return this.cardDetailForm.controls; }
  @ViewChild('modalCancelWeekly', {static: false}) private modalCancelWeekly: NgbModal;
  @ViewChild('modalWeeklySchedule', {static: false}) private modalWeeklySchedule: NgbModal;
  
  ngOnInit(): void {
    this.personalDetailForm = this.formBuilder.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      //postal_code: [''],
      //address: [''],
      //city: [''],
      password: [''],
      confirmPassword: ['']
      //notes: ['']
      });
    this.cardDetailForm = this.formBuilder.group({
      ccnumber: ['', [Validators.required, Validators.maxLength(19) , Validators.minLength(13)]],
      expirationMonth: ['', Validators.required],
      expirationYear: ['', Validators.required],
      cvv: ['', [Validators.required, Validators.maxLength(4) , Validators.minLength(3)]],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal_code: ['', Validators.required],
      checkAddress:['']
      });
    this.spinner = true;
     const body = {
      id: sessionStorage.getItem('user_id'),
    };
    
    this.userService.getCustomer(body).subscribe(
      (res: any) => {
        if(res.status){
          this.personalDetailForm.patchValue({
            name: res.customer.name,
            last_name: res.customer.last_name, 
            email: res.customer.email, 
            //postal_code: res.customer.postal_code, 
            //address: res.customer.address, 
            //city: res.customer.city, 
            phone_number: res.customer.phone_number
            //notes: res.customer.notes
          });        
          this.useWalletBalance = res.customer.use_wallet_balance;
        }        
        else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );

    const body2 = {
      customer_id: sessionStorage.getItem('user_id'),
    };

    this.userService.getStripeCard(body2).subscribe(
      (res: any) => {
        if(res.status && res.cards.length>0){
          this.cardDetailForm.patchValue({
            ccnumber: 'xxxxxxxxxxxx'+res.cards[0].last4,
            expirationMonth: res.cards[0].exp_month, 
            expirationYear: res.cards[0].exp_year,
            cvv: '', 
            addressOne: res.cards[0].address_line1, 
            addressTwo: res.cards[0].address_line2, 
            city: res.cards[0].address_city, 
            state: res.cards[0].address_state, 
            postal_code: res.cards[0].address_zip, 
          });        
          switch(res.cards[0].brand){
            case "MasterCard":
                this.icon = "assets/cards/mastercard.png";
              break;
            case "Visa":
              this.icon = "assets/cards/visa.png";
              break;
            case "American Express":
              this.icon = "assets/cards/amex.png";
              break;
            case "Discover":
              this.icon = "assets/cards/discover.png";
              break;
            case "JCB":
                this.icon = "assets/cards/jcb.png";
              break;
            case "UnionPay":
                this.icon = "assets/cards/UnionPay.png";
              break;     
            case "Diners Club":
                this.icon = "assets/cards/DinersClub.png";
              break;                            
            default:
              this.icon = "assets/cards/visa.png";
              break;
          }          
        }         
        else{
          this.editCard = true;
        }
      },
      (error: any) => {
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );


    this.bookingService.getAll(body).subscribe(
      (res: any) => {
        this.trips = res;
        console.log(this.trips);
        this.spinner = false;
      },
      (_error: any) => {
        this.snackBar.open(
          'Could not get list of bookings for this user.',
          'Dismiss',
          {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
        this.spinner = false;
      }
    );

    const bodyPreferredDrivers = {
      customer_id: sessionStorage.getItem('user_id'),
    };

    this.userService.getPreferredDrivers(bodyPreferredDrivers).subscribe(
      (res: any) => {
        if(res.status){          
          this.previous_preferred_drivers=this.splitArrayIntoChunksOfLen(res.drivers,3); 
          this.selected_driver =res.driver;
        }else{
          this.previous_preferred_drivers = 0;
          this.selected_driver ='';
        }
        this.spinner = false;
      },
      (_error: any) => {
        this.previous_preferred_drivers = 0;
        this.selected_driver ='';
        this.spinner = false;
      }
    );
    this.retrieveSubscription();    
    this.getServerTime();
    this.getCustomerBalance();
  }
  getCustomerBalance(){
    const body = {      
      customer_id: sessionStorage.getItem('user_id')
      };
      this.spinner = true;
    this.userService.getCustomerBalance(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){          
          this.wallet_balance = '$' + parseFloat(_res.balance).toFixed(2);        
        }else{
          this.wallet_balance = '$0';                              
        }                        
      },
      (_error: any) => {
        this.wallet_balance = '$0';                                      
      }
    );    
  }
  updateWalletBalanceUsage() {
    const body = {
      customer_id: sessionStorage.getItem('user_id'),  
      use_wallet: this.useWalletBalance
    };    
    this.spinner = true;
    this.userService.updateWalletUsage(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          }); 
        } else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });          
        }    
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );  
  }
  onCheckboxChange(e) {
    
  }
  getAutoOrderSchedule(){
    const body = {
      customer_id: sessionStorage.getItem('user_id')
    };
    this.bookingService.getAutoOrderSchedule(body).subscribe(
      (res: any) => {
        if(res.autoOrder){
          this.autoOrderExists = true;
          var datePipe = new DatePipe('en-US');
          this.next_scheduled = datePipe.transform(res.autoOrder.pickup_date, 'MM/dd/yyyy');
         // this.next_scheduled = Date(res.autoOrder.pickup_date);
          this.smallBag = res.autoOrder.small_bag;
          this.largeBag = res.autoOrder.large_bag;
          this.tip = res.autoOrder.tip;
          this.instructions = res.autoOrder.delivery_instructions;
          this.userAdjustedTimeFrame = this.timeFrames = res.autoOrder.time_frame_id;
          this.bookDate = this.pickup_date = res.autoOrder.pickup_date;
          //this.getPickupTimeFrames();
          if(res.autoOrder.type == 1){
            this.weeklyBool = true;
            this.BiweeklyBool = false;
          }else{
            this.weeklyBool = false;
            this.BiweeklyBool = true;
          }
        }else{
          this.next_scheduled = "Nothing Scheduled";
        }        
        this.getPickupTimeFrames();
      },
      (error: any) => {
        this.autoOrderExists = false;
        this.next_scheduled = "Nothing Scheduled";
        this.getPickupTimeFrames();
      }
      );
  }
  getServerTime(){
    this.bookingService.getServerTime().subscribe(
      (res: any) => {
        this.spinner = false;
        this.server_date_time = res.server_date_time;
        this.server_date = new Date(res.server_date);
        this.server_time = res.server_time;
        this.bookDate = new FormControl((new Date(this.server_date)).toISOString().substring(0, 10));
        this.bookDate = this.pickup_date = res.server_date;
        console.log("Server Date" + this.server_date);
        console.log("Server Date real" + res.server_date);
        console.log("Server time" + this.server_time);
        this.getAutoOrderSchedule();        
      },
      (error: any) => {
        this.getAutoOrderSchedule();        
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
      );
  }
  getPickupTimeFrames(){
    const body = {
      type: 1,
    };
    console.log("Profile time frame API called.");
    this.spinner = true;
    this.bookingService.getPickupTimeFrames(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){
          console.log(' User Time Frame selected: ' + this.userAdjustedTimeFrame);
          this.pickup_time_frames = res.time_frames;
          this.hours = new Date().getUTCHours()-5;
          this.minutes = new Date().getMinutes();
          console.log("Pick up control date "+ this.pickup_date);
          if(this.server_time){
            this.hours = this.server_time;
            console.log("server time: " + this.server_time);
          }          
          console.log("Hours Now" + this.hours);
          this.timeFrames = this.timeFrameSelected = String(this.userAdjustedTimeFrame);
          this.pickup_time_frames.forEach(element => {
            console.log("Time Limit " + element.time_limit);          
            if(this.hours < element.time_limit){
              element['display'] = false;
            }else{
              element['display'] = true;
            }            
          }); 
          let today_date = this.server_date.toISOString().substring(0, 10);    
          if(today_date < this.bookDate){                        
            this.pickup_time_frames.forEach(element => {
                element['display'] = false;                                      
            }); 
            console.log("today_date < this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
          }    
        
          if(today_date == this.bookDate){
        //    this.timeFrames = 0;
            this.pickup_time_frames.forEach(element => {
              if(this.hours < element.time_limit){
                element['display'] = false;
              }else{
                element['display'] = true;
              }
            }); 
            console.log("today_date == this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
          }
          if(today_date > this.bookDate){
         //   this.timeFrames = 0;
            this.pickup_time_frames.forEach(element => {
                element['display'] = true;        
            }); 
            console.log("today_date > this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
          }

          console.log('Time frames');
          console.log(this.pickup_time_frames);
          
        }         
        else{
          this.pickup_time_frames = null;                            
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  } 
  timeframeChange(){
    this.hours = new Date().getHours();
    this.minutes = new Date().getMinutes();
    this.timeFrameSelected = this.timeFrames;
  }
  getRandomNumber(){
    return Math.floor((Math.random() * 100) + 1);
  }
  getStatus(status: any) {
    switch (status) {
      case '1':
        return 'Pick Up Scheduled';

      case '12':
        return 'Pick Up Scheduled';

      case '2':
        return 'Driver on the way';

      case '2.1':
        return 'Driver has arrived';

      case '2.2':
        return 'Picked up';

      case '3':
        return 'Returning to Customer';

      case '3.1':
        return 'Awaiting return confirm';

      case '6':
        return 'Complete';

      case '11':
        return 'Book later appt canceled by partner';
      case '12':
          return 'Book later appt accepted by partner';
      case '10':        
        return 'Book Later canceled by customer';
      case '9':
        return 'Book now Canceled by partner';
      case '5':
        return 'Cancelled by customer';

      default:
        return '';
    }
  }
  cancelOrderConfirmation(id) {
    
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel Order',
        message: 'Are you sure, you want to cancel the order?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {        
        this.cancelOrder(id);
      }
    });    
    
  }
  cancelOrder(id){
    const body = {
      booking_id: id,
      mode:"schedule_customer_cancel"
      };
    this.spinner = true;
    this.bookingService.cancelOrder(body).subscribe(
      (res: any) => {
        if (res.status) {  
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                        
          this.router.navigate(['/order']);
        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {        
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );    
  }
  updateUser() {
    
    if (this.personalDetailForm.invalid) {
      return;
    }

    const body = {
      id: sessionStorage.getItem('user_id'),  
      name: this.personalDetailForm.controls['name'].value,
      email: this.personalDetailForm.controls['email'].value,
      password: this.personalDetailForm.controls['password'].value,
      confirmPassword: this.personalDetailForm.controls['confirmPassword'].value,
      last_name: this.personalDetailForm.controls['last_name'].value,
      postal_code: '',//this.personalDetailForm.controls['postal_code'].value,
      address:  '',//this.personalDetailForm.controls['address'].value,
      city:  '',//this.personalDetailForm.controls['city'].value,
      phone_number: this.personalDetailForm.controls['phone_number'].value,
      notes: '',//this.personalDetailForm.controls['notes'].value,
    };    
    this.spinner = true;
    this.userService.updateUser(body).subscribe(
      (res: any) => {
        this.spinner = false;
        this.snackBar.open('Details updated successfully!', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-success-green'],
          verticalPosition: 'top',
        });
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );  
  }

  modifyCard(){
    this.editCard = true; 
  }
  updateCard() {
    // stop here if form is invalid
    if (this.cardDetailForm.invalid) {
      return;
    }

    const body = {
      id: sessionStorage.getItem('user_id'),
      card_number: this.cardDetailForm.controls.ccnumber.value,
      card_month_expiry: this.cardDetailForm.controls.expirationMonth.value,
      card_year_expiry: this.cardDetailForm.controls.expirationYear.value,
      card_cvv: this.cardDetailForm.controls.cvv.value    ,
      postal_code: this.cardDetailForm.controls.postal_code.value,
      address1: this.cardDetailForm.controls.addressOne.value,
      address2: this.cardDetailForm.controls.addressTwo.value,
      city: this.cardDetailForm.controls.city.value,
      state: this.cardDetailForm.controls.state.value,
    };
    this.spinner = true;
      this.userService.updateCard(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                    
          this.cardDetailForm.controls.ccnumber.setValue('xxxxxxxxxxxx'+this.cardDetailForm.controls.ccnumber.value.slice(this.cardDetailForm.controls.ccnumber.value.length - 4));
          this.editCard = false; 
        }else{
          this.snackBar.open(
            _res.message,
            'Dismiss',
            {
              duration: 4000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            }
          );
        }       
      },
      (_error: any) => {
        this.spinner = false;
        this.snackBar.open(
          _error.error.message,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
      }
    );
  }
  cancelSubscription(){
    const body = {
      customer_id: sessionStorage.getItem('user_id')
      };
      this.spinner = true;
    this.userService.cancelSubscription(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                              
          this.subscription.suspended = true;
        }else{
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });                              
        }                
      },
      (_error: any) => {
        this.spinner = false;
        this.snackBar.open(
          _error.error.message,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
      }
    );
  }
  activateSubscription(){
    const body = {
      customer_id: sessionStorage.getItem('user_id')
      };
      this.spinner = true;
    this.userService.activateSubscription(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });         
          this.subscription.suspended = false;
        }else{
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });                              
        }                
      },
      (_error: any) => {
        this.spinner = false;
        this.snackBar.open(
          _error.error.message,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
      }
    );
  }
  retrieveSubscription(){
    const body = {
      customer_id: sessionStorage.getItem('user_id')
      };
      this.spinner = true;
    this.userService.getSubscription(body).subscribe(
      (_res: any) => {
        if(_res.status){ 
          this.spinner = false;         
          if(Subscription.Active === _res.subscription_status){
            this.subscription.plan = Subscription.Plan;            
            this.subscription.end = new Date(_res.subscription_end.replace(/\s/, 'T')).toDateString()+" "
            this.subscription.start = _res.subscription_start;
            this.subscription.suspended = _res.cancel_subscription_at_period_end;          
            this.subscription.status = true;
          }else if(Subscription.Trialing === _res.subscription_status){
            this.subscription.plan = Subscription.Plan + ' - Free Trial';
            this.subscription.end = new Date(_res.subscription_end.replace(/\s/, 'T')).toDateString()+" "
            this.subscription.start = _res.subscription_start;
            this.subscription.suspended = _res.cancel_subscription_at_period_end;          
            this.subscription.status = true;
          }else if(Subscription.Canceled === _res.subscription_status || Subscription.Ended === _res.subscription_status  || Subscription.Unsub === _res.subscription_status){
            this.subscription.plan = Subscription.Basic;
            this.subscription.end = 'Unlimited';
            this.subscription.start = 'Unlimited';            
            this.subscription.status = false;
          }          
          else{
            this.subscription.plan = Subscription.Basic;
            this.subscription.status = false;
          }
        }else{
          this.subscription.plan = Subscription.Basic;
          this.subscription.status = false;          
        }                
      },
      (_error: any) => {
        this.spinner = false;
        this.snackBar.open(
          _error.error.message,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
      }
    );
  }
  splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [], i = 0, n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }
  driverSelected(driver){
    this.driver = driver;
    this.driver_selected = true;
  }
  confirmDriver(driver){   
    const bodyPreferredDriver = {
      customer_id: sessionStorage.getItem('user_id'),
      driver_id: this.driver.id,
    };
    this.userService.updateReferral(bodyPreferredDriver).subscribe(
      (res: any) => {
        if(res.status){
          this.driver = driver;
          this.driver_selected = false;          
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });              
          this.selected_driver = driver;          
        }else{          
          this.driver_selected = true;          
          this.snackBar.open('Some error occured. Please try again later.', 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
        this.spinner = false;
      },
      (_error: any) => {
        this.snackBar.open('Some error occured. Please try again later.', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  }
  cancelDriver(driver){
    this.driver = driver;
    this.driver_selected = false;    
  }
  removePreferredParter(){
    const bodyPreferredDriver = {
      customer_id: sessionStorage.getItem('user_id'),
      driver_id: this.selected_driver.id,
    };
    this.userService.removePreferredPro(bodyPreferredDriver).subscribe(
      (res: any) => {
        if(res.status){
          this.spinner = false;
          this.selected_driver = '';
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                        
        }else{                              
          this.snackBar.open('Some error occured. Please try again later.', 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
        this.spinner = false;
      },
      (_error: any) => {
        this.snackBar.open('Some error occured. Please try again later.', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  }
  addPreferredParterCode(){
    if(this.code == undefined || this.code == ''){
      this.snackBar.open('Enter Referral Code please', 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });      
      return;
    }
    const bodyPreferredDriver = {
      customer_id: sessionStorage.getItem('user_id'),
      code: this.code,
    };
    this.userService.verifyPreferredProCode(bodyPreferredDriver).subscribe(
      (res: any) => {        
        if(res.status){
          this.spinner = false;
          this.driver = res.driver;
          this.driver_selected = true;                      
        }else{                              
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
          this.driver_selected = false;                      
        }
        this.spinner = false;
      },
      (_error: any) => {
        this.snackBar.open('Some error occured. Please try again later.', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
        this.driver_selected = false;                      
      }
    );
    /*
    this.userService.addPreferredParterCode(bodyPreferredDriver).subscribe(
      (res: any) => {        
        if(res.status){
          this.spinner = false;
          this.selected_driver = res.driver;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                        
        }else{                              
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
        this.spinner = false;
      },
      (_error: any) => {
        this.snackBar.open('Some error occured. Please try again later.', 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );*/
  }
  cancelSchedule(){
    console.log("Schedule Cancelled!");    
    this.modalService.open(this.modalCancelWeekly, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      //if(result != undefined && result === 'Confirm click'){
        console.log('Result click: '+ result);
      //}      
    }, (reason) => {      
    });  
    
  }
  changeSchedule(){
    console.log("Schedule Changed!");
    this.modalService.dismissAll();
    this.resetPickupPopup();

    // Open the new popup to change the Schedule with Date,time frame, and Weekly/biweekly options. 
    this.modalService.open(this.modalWeeklySchedule, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      
    }, (reason) => {      
    });
  }
  confirmCancelSchedule(){
    console.log("Confirm Cancel Schedule Clicked!");
    this.modalService.dismissAll();
    // Call the cancel Weekly Schedule API ******
    const body = {
      customer_id: sessionStorage.getItem('user_id')
    };
    this.bookingService.cancelAutoOrder(body).subscribe(
      (res: any) => {
        this.autoOrderExists = false;        
        this.next_scheduled = "Nothing Scheduled";
        this.snackBar.open(res.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-success'],
          verticalPosition: 'top',
        });
      },
      (error: any) => {
        this.autoOrderExists = true;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
      );
  }
  reschedule(){
    console.log("Reschedule clicked!");
    this.modalService.dismissAll();
    this.resetPickupPopup();
    // Open the new popup to change the Schedule with Date,time frame, and Weekly/biweekly options. 
    this.modalService.open(this.modalWeeklySchedule, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      
    }, (reason) => {      
    });
  }
  confirmScheduleChanged(){
    console.log("Confirm Schedule Changed!");
    console.log("Pickup date: "+this.pickup_date);
    console.log("Time Frame: "+this.timeFrames);    
    if(this.smallBag == 0 && this.largeBag == 0){
      this.snackBar.open("You must select at least one bag", 'Dismiss', {
        duration: 3000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    // Call the update/setup Weekly Schedule API *****
    const body = {
      customer_id: sessionStorage.getItem('user_id'),     
      type:this.weeklySelected,
      pickup_date: this.pickup_date,
      time_frame_id: this.timeFrames,
      small_bag: this.smallBag,
      large_bag:this.largeBag,
      tip: this.tip,
      delivery_instructions: this.instructions
    };
    this.spinner = true;
    this.bookingService.setupAutoOrder(body).subscribe(
      (res: any) => {
        if(res.status){        
          this.spinner = false;          
          this.userAdjustedTimeFrame = this.timeFrameSelected = this.timeFrames;
          //this.next_scheduled = res.autoOrder.pickup_date;
          var datePipe = new DatePipe('en-US');
          this.next_scheduled = datePipe.transform(res.autoOrder.pickup_date, 'MM/dd/yyyy');
          this.smallBag = res.autoOrder.small_bag;
          this.largeBag = res.autoOrder.large_bag;
          this.tip = res.autoOrder.tip;
          this.instructions = res.autoOrder.delivery_instructions;
          this.userAdjustedTimeFrame = this.timeFrames = this.timeFrameSelected = res.autoOrder.time_frame_id;
          this.bookDate = this.pickup_date = res.autoOrder.pickup_date;      
          if(res.autoOrder.type == 1){
            this.weeklyBool = true;
            this.BiweeklyBool = false;
          }else{
            this.weeklyBool = false;
            this.BiweeklyBool = true;
          }
          this.modalService.dismissAll();
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success'],
            verticalPosition: 'top',
          });
          
        }else{
          this.spinner = false;          
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {
        this.spinner = false;          
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
      );
  }
  dateChanged(date){
    this.pickup_date = this.bookDate;//date.value;
    console.log("pickup date: "+ this.pickup_date);
    console.log("pickup date variable: "+ this.bookDate);
    //this.hours = new Date().getHours();
    this.hours = new Date().getUTCHours()-5;
    this.minutes = new Date().getMinutes();
    //let today_date = ((new Date()).toISOString().substring(0, 10));
    let today_date = this.server_date.toISOString().substring(0, 10);    
    if(this.server_time){
      this.hours = this.server_time;
      console.log("server time: " + this.server_time);
    }
    if(today_date < this.bookDate){            
      this.pickup_time_frames.forEach(element => {
          element['display'] = false;        
      }); 
      console.log("today_date < this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
    }    
  
    if(today_date == this.bookDate){
      this.timeFrames = 0;
      this.pickup_time_frames.forEach(element => {
        if(this.hours < element.time_limit){
          element['display'] = false;
        }else{
          element['display'] = true;
        }
      }); 
      console.log("today_date == this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
    }
    if(today_date > this.bookDate){
      this.timeFrames = 0;
      this.pickup_time_frames.forEach(element => {
          element['display'] = true;        
      }); 
      console.log("today_date > this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
    }
    console.log('Time frames');
    console.log(this.pickup_time_frames);    
  }
  radioChange(event: MatRadioChange) {        
    console.log("Selected value: " + event.value);
    this.weeklySelected = event.value;
  }
  resetPickupPopup(){
    this.getAutoOrderSchedule();
    /*
    let today_date = this.server_date.toISOString().substring(0, 10);  
    var datePipe = new DatePipe('en-US');
    this.bookDate = this.pickup_date = datePipe.transform(this.next_scheduled, 'yyyy-MM-dd');
    //this.bookDate = this.pickup_date = this.next_scheduled;  
    if(today_date < this.bookDate){            
      this.pickup_time_frames.forEach(element => {
          element['display'] = false;        
      });       
      console.log("today_date < this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
    }    
  
    if(today_date == this.bookDate){
      this.timeFrames = 0;
      this.pickup_time_frames.forEach(element => {
        if(this.hours < element.time_limit){
          element['display'] = false;
        }else{
          element['display'] = true;
        }
      }); 
      console.log("today_date == this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
    }
    if(today_date > this.bookDate){
      this.timeFrames = 0;
      this.pickup_time_frames.forEach(element => {
          element['display'] = true;        
      }); 
      console.log("today_date > this.bookDate today_date: " + today_date + "   Selected date: " + this.bookDate);
    }
    this.timeFrameSelected = this.timeFrames = this.userAdjustedTimeFrame;
    */
  }
}

