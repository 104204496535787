import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../core/services/auth.service';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterComponent } from '../register/register.component';
import { ResetPasswordComponent } from '../pages/reset-password/reset-password.component';

import { Router } from '@angular/router';

enum Subscription{
  Unsub = 'unsub',
  Canceled = 'canceled',
  Ended = 'ended',
  Active = 'active',
  Trialing = 'trialing'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [NavbarComponent],
})
export class LoginComponent implements OnInit {
  spinner: boolean = true;
  errorMessage: string = null;
  userForm: any;
  constructor(
    private navbar: NavbarComponent,
    private authService: AuthService,
    public dialogRef: MatDialogRef<LoginComponent>,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    sessionStorage.clear();
    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
    this.spinner = false;
  }

  login() {
    this.spinner = true;
    const body = {
      email: this.userForm.value.email,
      password: this.userForm.value.password,
      type: 'customer',
    };
    this.authService.login(body).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status){
          sessionStorage.setItem('user_id', res.customer.id);
          sessionStorage.setItem('customer', res.customer);
          localStorage.setItem('user_id', res.customer.id);
          localStorage.setItem('customer', res.customer);
          localStorage.setItem('subscription', res.subscription.original.data['subscription_status']);
          sessionStorage.setItem('notes', res.customer.notes ? res.customer.notes : '');
          this.navbar.isLoggedIn();
          this.dialogRef.close(true);

          const subscrip = res.subscription.original.data['subscription_status'];
          //if(subscrip === Subscription.Canceled || subscrip === Subscription.Ended || subscrip === Subscription.Unsub){
            this.router.navigate(['/plans']);  
          //}else if(subscrip === Subscription.Active || subscrip === Subscription.Trialing){
            //this.router.navigate(['/order']);
          //}          
        }else{
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });        
        }        
      },
      (_error: any) => {        
        this.spinner = false;
        this.snackBar.open(_error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });        
      }
    );
  }

  signup() {
    this.dialog.open(RegisterComponent, {
      autoFocus: false,
    });
    this.dialogRef.close();
  }
  forgetPassword(){
    this.dialog.open(ResetPasswordComponent, {
      autoFocus: false,
    });
    this.dialogRef.close();
  }
}
