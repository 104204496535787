import { Component, OnInit } from '@angular/core';
import {FormControl, Validators,FormGroup,FormBuilder, FormArray} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';

interface Month {
  value: string;
  viewValue: string;
}

interface Year {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  spinner: boolean = true;
  cardForm: any;
  submitted: boolean = false;
 
  name: any;
  email: any;
  password: any;
  confirmPassword: any;
  ccnumber: any;
  ccname: any;
  expirationMonth: any;
  expirationYear: any;
  cvv: any;
  trips: any;
  notes: any;
  PAT_NAME: any;
  editCard: boolean = false;
  icon: string = "assets/cards/visa.png";

  months: Month[] = [
    {value: '1', viewValue: 'January'},
    {value: '2', viewValue: 'February'},
    {value: '3', viewValue: 'March'},
    {value: '4', viewValue: 'April'},
    {value: '5', viewValue: 'May'},
    {value: '6', viewValue: 'June'},
    {value: '7', viewValue: 'July'},
    {value: '8', viewValue: 'August'},
    {value: '9', viewValue: 'September'},
    {value: '10', viewValue: 'October'},
    {value: '11', viewValue: 'November'},
    {value: '12', viewValue: 'December'}
  ];

  years: Year[] = [
    {value: '2021', viewValue: '2021'},
    {value: '2022', viewValue: '2022'},
    {value: '2023', viewValue: '2023'},
    {value: '2024', viewValue: '2024'},
    {value: '2025', viewValue: '2025'},
    {value: '2026', viewValue: '2026'},
    {value: '2027', viewValue: '2027'},
    {value: '2028', viewValue: '2028'},
    {value: '2029', viewValue: '2029'},
    {value: '2030', viewValue: '2030'},
    {value: '2031', viewValue: '2031'},
    {value: '2032', viewValue: '2032'},
    {value: '2033', viewValue: '2033'},
    {value: '2034', viewValue: '2034'},
    {value: '2035', viewValue: '2035'},
    {value: '2036', viewValue: '2036'},
    {value: '2037', viewValue: '2037'},
    {value: '2038', viewValue: '2038'},
    {value: '2039', viewValue: '2039'},
    {value: '2040', viewValue: '2040'},
    {value: '2041', viewValue: '2041'},
    {value: '2042', viewValue: '2042'},
    {value: '2043', viewValue: '2043'},
    {value: '2044', viewValue: '2044'},
    {value: '2045', viewValue: '2045'},
    {value: '2046', viewValue: '2046'},
    {value: '2047', viewValue: '2047'},
    {value: '2048', viewValue: '2048'},
    {value: '2049', viewValue: '2049'},
    {value: '2050', viewValue: '2050'},
    {value: '2051', viewValue: '2051'},
    {value: '2052', viewValue: '2052'},
  ];
  
  constructor(    
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,    
    private userService: UserService
  ) {}
  
  get c() { return this.cardForm.controls; }

  ngOnInit(): void {
    this.cardForm = this.formBuilder.group({
      ccname:['', Validators.required],
      ccnumber: ['', [Validators.required, Validators.maxLength(19) , Validators.minLength(13)]],
      expirationMonth: ['', Validators.required],
      expirationYear: ['', Validators.required],
      cvv: ['', [Validators.required, Validators.maxLength(4) , Validators.minLength(3)]],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal_code: ['', Validators.required],
      agree: [false, Validators.required],
      checkAddress:['']
    });
    this.spinner = false;    
    this.getCard();
  }
  getCard(){
    const body2 = {
      customer_id: sessionStorage.getItem('user_id'),
    };
    this.spinner = true;
    this.userService.getStripeCard(body2).subscribe(
      (res: any) => {
        this.spinner = false;
        if(res.status && res.cards.length>0){
          this.cardForm.patchValue({
            ccnumber: 'xxxxxxxxxxxx'+res.cards[0].last4,
            expirationMonth: res.cards[0].exp_month, 
            expirationYear: res.cards[0].exp_year,
            cvv: '', 
            addressOne: res.cards[0].address_line1, 
            addressTwo: res.cards[0].address_line2, 
            city: res.cards[0].address_city, 
            state: res.cards[0].address_state, 
            postal_code: res.cards[0].address_zip, 
            ccname: res.cards[0].name, 
          });        
          switch(res.cards[0].brand){
            case "MasterCard":
                this.icon = "assets/cards/mastercard.png";
              break;
            case "Visa":
              this.icon = "assets/cards/visa.png";
              break;
            case "American Express":
              this.icon = "assets/cards/amex.png";
              break;
            case "Discover":
              this.icon = "assets/cards/discover.png";
              break;
            case "JCB":
                this.icon = "assets/cards/jcb.png";
              break;
            case "UnionPay":
                this.icon = "assets/cards/UnionPay.png";
              break;     
            case "Diners Club":
                this.icon = "assets/cards/DinersClub.png";
              break;                            
            default:
              this.icon = "assets/cards/visa.png";
              break;
          }          
        }         
        else{
          this.editCard = true;
        }
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  }
  modifyCard(){
    this.editCard = true; 
  }
  cancelCard(){
    this.editCard = false; 
  }
  updateCard() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.cardForm.invalid) {
      return;
    }

    const body = {
      id: sessionStorage.getItem('user_id'),
      card_number: this.cardForm.controls.ccnumber.value,
      card_month_expiry: this.cardForm.controls.expirationMonth.value,
      card_year_expiry: this.cardForm.controls.expirationYear.value,
      card_cvv: this.cardForm.controls.cvv.value    ,
      postal_code: this.cardForm.controls.postal_code.value,
      address1: this.cardForm.controls.addressOne.value,
      address2: this.cardForm.controls.addressTwo.value,
      city: this.cardForm.controls.city.value,
      state: this.cardForm.controls.state.value,
      card_user_name:this.cardForm.controls.ccname.value,
    };
    this.spinner = true;
      this.userService.updateCard(body).subscribe(
      (_res: any) => {
        this.spinner = false;
        if(_res.status){        
          this.snackBar.open(_res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });                    
          this.cardForm.controls.ccnumber.setValue('xxxxxxxxxxxx'+this.cardForm.controls.ccnumber.value.slice(this.cardForm.controls.ccnumber.value.length - 4));
          this.editCard = false; 
        }else{
          this.snackBar.open(
            _res.message,
            'Dismiss',
            {
              duration: 4000,
              panelClass: ['notif-failure'],
              verticalPosition: 'top',
            }
          );  
          this.editCard = true; 
        }        
      },
      (_error: any) => {
        this.spinner = false;
        this.submitted = false;
        this.snackBar.open(
          _error.error.message,
          'Dismiss',
          {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          }
        );
      }
    );
  }
}
