<div class="container-custom h-100" id="divDialog">
    <div class="row m-0" *ngIf="showclose">
      <span style="font-size:1.5rem;cursor: pointer;" class="close-button ml-auto px-2" (click)="close()">X</span>
    </div>
    <div class="row m-0" style="height: 80%;">
        <div class="col h-100">
            <div class="d-flex flex-column h-100">
                <div class="card bg-transparent text-white custom-card h-100">
                    <div class="card-body p-0">
                        <div id="divChecking" class="h-100" [style.display]="'none'">
                          <div class="row align-items-center h-100">
                            <div class="col-12 mx-auto text-center">
                              <span><h1 style="font-size:2.5rem;"><i class="fas fa-check check-color"></i> Checking Service Area</h1></span>                            
                            </div>
                          </div>                            
                        </div>    
                        <div id="divFound" class="h-100"  [style.display]="'none'">
                          <div class="row align-items-center h-100">
                            <div class="col-12 mx-auto text-center">
                              <h1 style="font-size:2.5rem;"><i class="fas fa-check check-color"></i> YAY we Service your Area</h1>
                            </div>
                          </div>
                        </div>    
                        <div id="divNotFound" class="h-100" [style.display]="'none'">
                          <div class="row justify-content-center h-100">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h1 style="font-size:2rem;" class="text-center">Oh No! we aren't in your area yet.</h1>                            
                              <h4 style="font-size:1.7rem;color:white" class="text-center">Enter your email below and we will let you know as soon as we do.</h4>                            
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                              <div class="card text-white text-center card-notfound h-100">
                                  <div class="card-body">
                                      <div class="row align-items-center h-100">
                                        <div class="col-12 mx-auto text-center">
                                          <input type="text" id="email" name="email" [(ngModel)]="email" placeholder="Email" class="form-control border" />
                                          <button class="btn btn-email" (click)="saveEmail()">
                                            SUBMIT
                                        </button>
                                        </div>                                        
                                      </div>
                                  </div>
                              </div>
                            </div>                            
                          </div>                            
                        </div>    
                        <div id="divRegister" [style.display]="'none'">                            
                            <h1 class="text-center" style="font-size:2rem;">Let's Get You All Set Up.</h1>                            
                            <div class="row row px-xs-0 px-sm-4 px-md-0 px-lg-7 mx-xs-3 mx-sm-4 mx-md-0 mx-lg-5 pt-xs-12 pt-sm-0 pt-md-4 pt-lg-4">
                                <div class="col-sm-12 col-md-6 py-2">
                                    <div id="cardRegistration" class="card card-register h-100">
                                        <div class="card-body text-black">
                                            <form [formGroup]="registerForm">
                                                <div class="form-wrap">
                                                  <h4>FREE! Account Setup</h4>
                                                  <div class="form-inr">
                                                      <div class="row">
                                                        <div class="col-sm-6">
                                                          <div class="form-group">                                                            
                                                            <input type="text" placeholder="First Name" formControlName="name" class="form-control border" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                                <div *ngIf="f.name.errors.required">First Name is required</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                          <div class="form-group">
                                                            <input type="text" placeholder="Last Name"  formControlName="last_name" class="form-control border" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                                                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                                                <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="row">
                                                        <div class="col-sm-6">
                                                          <div class="form-group">                                                            
                                                            <input type="text" placeholder="Email address" formControlName="email" class="form-control border" [ngClass]="{ 'is-invalid': submitted &&  f.email.errors }" />
                                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                                <div *ngIf="f.email.errors.email">Valid email address required</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                          <div class="form-group">                                                            
                                                            <input type="tel" placeholder="Phone Number" formControlName="phone_number" class="form-control border" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }" maxlength="10"  minlength="8" />
                                                            <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                                                <div *ngIf="f.phone_number.errors.required">Phone number is required</div>
                                                                <div *ngIf="f.phone_number.errors?.maxlength || f.phone_number.errors?.minlength">Phone Number must be 8 to 10 digits long</div>                                        
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>                        
                                                      <div class="row">
                                                        <div class="col-sm-6">
                                                          <div class="form-group">                                                            
                                                            <input type="password" placeholder="Password" formControlName="password" class="form-control border" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  maxlength="50"  minlength="8"  />
                                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                                <div *ngIf="f.password.errors?.minlength">Password must be minimum 8 characters long</div>                                        
                                                                <div *ngIf="f.password.errors?.maxlength">Password must be maximum 50 characters long</div>                                        
                                                            </div>  
                                                          </div>
                                                        </div>
                                                   
                                                      <div class="col-sm-6">                                                        
                                                        <input type="password" placeholder="Confirm Password" formControlName="confirmPassword" class="form-control border" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" maxlength="50"  minlength="8" />
                                                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>                  
                                                            <div *ngIf="f.confirmPassword.errors?.minlength">Confirm password must be minimum 8 characters long</div>                                        
                                                            <div *ngIf="f.confirmPassword.errors?.maxlength">Confirm password must be maximum 50 characters long</div>                                                          
                                                        </div>  
                                                      </div>
                                                    </div>                                                                                                          
                                                    <div class="row">
                                                      <div class="col-sm-12 text-left">
                                                        <div class="form-check">
                                                          <label class="form-check-label">
                                                            <input type="checkbox" formControlName='agree' class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.agree.errors }"  required>I agree to the <a  class="terms-policy" (click)="goToLink('https://laundryod.com/blog/terms-conditions')"> Terms & conditions </a> and <a (click)="goToLink('https://laundryod.com/blog/privacy-policy')" class="terms-policy">Privacy Policy</a>.
                                                          </label> 
                                                        </div>    
                                                        <div *ngIf="submitted && f.agree.errors" class="invalid-feedback">
                                                          <div *ngIf="f.agree.errors.required">You must agree to the Terms & Conditions and Privacy Policy</div>
                                                        </div>            
                                                      </div>
                                                    </div>                              
                                                  </div>
                                                </div>
                                            </form>          
                                            <div class="mt-2">      
                                                <div class="col-12 text-center">
                                                  <button class="btn register-button" color="primary" (click)="register()">
                                                    Register
                                                  </button>
                                                </div>
                                            </div>             
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 py-2">
                                    <div id="cardDashboard" class="card card-register card-disabled h-100">
                                        <div class="card-body text-center" id="divDashboard">
                                            <h4>You are all set!</h4>
                                            <div class="row p-2">
                                                <div class="col-12 text-center">
                                                    <button class="btn schedule-button" (click)="schedule()">
                                                      Schedule Now
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row p-2">
                                              <div class="col-12">
                                                <p class="or-text">OR</p>
                                              </div>
                                            </div>
                                            <div class="row p-2">
                                                <div class="col-12 text-center">
                                                    <button class="btn dashboard-button" (click)="dashboard()">
                                                      Dashboard
                                                    </button>
                                                  </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>                                
                            </div>  
                            <div class="row mt-sm-0 mt-md-5 lg-mt-5 mx-0 px-0">
                              <div class="col-sm-12">
                                  <p class="step-text">Step {{stepnumber}} OF 2</p>
                              </div>
                            </div>                          
                        </div>    
                        <div id="divLogin" [style.display]="'none'">
                            <h1 style="font-size:2rem;"><i class="fas fa-check check-color"></i> Let's GET You All Set Up</h1>                            
                        </div>    
                        <div id="divAddCoverage" [style.display]="'none'">
                            <h1 style="font-size:2.5rem;"><i class="fas fa-check check-color"></i> Thank you for submitting your area request.</h1>                            
                        </div>                         
                    </div>  
                </div>
            </div>
        </div>
    </div>
</div>