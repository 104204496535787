<div class="page-content">
    <div class="container  mt-5" id="account-page">
        <div class="sec-title">
            <h3 class="text-left">History</h3>
        </div>  
        <table class="table table-bordered mt-3">
            <thead class="thead-light">
            <tr>
                <th class=" text-center">Description</th>
                <th class=" text-center">Amount</th>              
                <th class=" text-center">Date</th>              
            </tr>
            </thead>
            <tr *ngFor="let balance of balances| paginate: {id: 'server', itemsPerPage: per_page, currentPage: p, totalItems: total }">
                <td>{{ balance.reason }}</td>
                <td class="text-center text-danger" *ngIf="balance.amount < 0"> {{"( $"+ (balance.amount* (-1)).toFixed(2) +" )" }} </td>
                <td class="text-center text-primary" *ngIf="balance.amount >= 0">${{ balance.amount.toFixed(2) }}</td>
                <td class="text-center">{{ parseDate(balance.created_at)}}</td>                               
            </tr>
          </table>            
          <pagination-controls (pageChange)="pageChangeEvent($event)" id="server"    ></pagination-controls>
    </div>
</div>