import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegisterComponent } from 'src/app/register/register.component';
import { LoginComponent } from '../../login/login.component';
import { ServiceareaComponent } from 'src/app/pages/servicearea/servicearea.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {
  constructor(private router: Router, public dialog: MatDialog) {}

  openLoginModal() {
    this.dialog.open(LoginComponent, {
      autoFocus: false,
    });
  }

  openRegisterModal() {
    /*this.dialog.open(RegisterComponent, {
      autoFocus: false,
    });*/
    localStorage.removeItem('service_coverage');
    this.dialog.open(ServiceareaComponent, {
      autoFocus: false,
      width: '90vw',        
      height: '64vh',
      hasBackdrop: false,                
      panelClass: ['custom-modalbox']
    });  
  }

  logout() {
    if (confirm('Are you sure you want to log out?')) {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/']);
    } else {
      return false;
    }
  }

  isLoggedIn() {
    if ('user_id' in sessionStorage) {
      return true;
    } else {
      return false;
    }
  }
  setSubscription(){
    
  }
  schedule(){
   // this.closeFunction();
    if(this.isLoggedIn()){           
      this.router.navigate(['/order']);   
    }else{
      this.openLoginModal();             
    }
  }
  scroll(id){
    this.router.navigate(['/'+'#'+id]);   
    const elmnt = document.getElementById(id);
    if(elmnt != null){
      elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }    
  }
  closeFunction()
  { 
     document.getElementById('navbarSupportedContent').style.display='none';
  }
  closebanner()
  { 
     document.getElementById('bannerTop').style.display='none';
  }
  goToLink(url: string){
    window.open(url, "_blank");
  }
  opensmbx(){
    window.open("https://smbx.in/wld", "_blank");
  }
  downloadApps(){
    window.open("https://laundryondemand.page.link/app", "_blank");
  }
}
