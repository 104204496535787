import { ElementRef,Component, OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BookingService } from 'src/app/core/services/booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import {FormControl,Validators,FormGroup,FormBuilder, FormArray} from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Router} from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { LoginComponent } from 'src/app/login/login.component';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  updatePasswordForm: FormGroup;
  email: any;
  spinner: boolean;
  customer_id:any;
  password: any;
  confirmPassword:any;
  submitted: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,    
    public dialogRef: MatDialogRef<ResetPasswordComponent>,    
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal) { }

 // convenience getter for easy access to form fields
  get f() { return this.updatePasswordForm.controls; }
  
  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.maxLength(50) , Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(50) , Validators.minLength(8)]]
      });
  }
  resetPassword(){
    this.submitted = true;
    if(this.updatePasswordForm.value.password !== this.updatePasswordForm.value.confirmPassword){
      this.snackBar.open('Error: Password mismatch!', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      });
      return;
    }
    if (this.updatePasswordForm.invalid) {
      this.snackBar.open('All fields are required', 'Dismiss', {
        duration: 4000,
        panelClass: ['notif-failure'],
        verticalPosition: 'top',
      }); 
      return;
    }    
    const body = {
      code: this.updatePasswordForm.controls['code'].value,
      password: this.updatePasswordForm.controls['password'].value,
      id: localStorage.getItem('reset_password_customer_id')
    };    
    this.spinner = true;
    this.userService.changePassword(body).subscribe(
      (res: any) => {
        if(res.status){
          this.spinner = false;
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-success-green'],
            verticalPosition: 'top',
          });
         
          this.dialog.open(LoginComponent, {
            autoFocus: false,
          });
          this.dialogRef.close();

        }else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 4000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });  
        }    
      },
      (error: any) => {
        this.spinner = false;
        this.snackBar.open(error.error.message, 'Dismiss', {
          duration: 4000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );  
  }
}
