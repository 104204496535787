<!-- ------------------------------ -->
    <section id="ld_banner-section">
      <div class="logo dk-logo">
      
      </div>
      <div class="container p-0">
        <div class="row m-0">
          <div class="col-8 col-lg-6 pr-5 pl-lg-0">
            <div class="ld_heading">

              <h1><br class="d-none d-md-block d-lg-block d-xl-block">Relax!</h1>
              <p><span>You are a couple of taps away from quiet quitting laundry.</span></p>

              <p class="ld_text">Check Service Area</p>

              <form>
              <div class="form-row">
                  <div class="form-group col-sm-12">
                    <div class="centered input-search-wrap">
                      <input
                        type="text"
                        class="search-input"
                        placeholder="Enter Address..."
                        matGoogleMapsAutocomplete                     
                        [(ngModel)]="address"
                        name="address"
                        
                      />
                      <button class="btn btn-success" (click)="checkServiceArea()"><img src="assets/images/white-arrow.png"></button>
                    </div>
                  </div>
              </div>
            </form>           
          </div>
        </div>
      </div>      
      <div class="row d-none d-sm-done d-md-none d-lg-block d-xl-block">
          <div class="col-12">
            <div class="download-btn">
            <a href="https://apps.apple.com/us/app/laundry-on-demand/id1530503346" class="btn-link" target="_blank"><img src="assets/images/apple-icon.png" class="img-fluid"></a>
            <a href="https://play.google.com/store/apps/details?id=com.redsLaundry.rider" class="btn-link" target="_blank"><img src="assets/images/google-play-icon.png" class="img-fluid"></a>
          </div>
        </div>
      </div>      
    </div>
  </section>


<!-- --------------pricing-section------------- -->
  <section id="ld_pricing-section" class="space" >
    <div class="container">
      <div class="ld_pricing-heading">
        <h3>Low Flat Rate Pricing</h3>
        <p>No weighing no worrying.  Know exactly how much its<br> going to be before you schedule.</p>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="ld_pricing-wrap">

            <h6>small Bag $30</h6>
            <img src="assets/images/small-bag1.png" class="img-fluid" alt="">
            <p>One and a half kitchen trash bags.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="ld_pricing-wrap">
            <h6>large Bag $45</h6>
            <img src="assets/images/big-bag.webp" class="img-fluid" alt="">
            <p>Three kitchen trash bags.</p>
          </div>
        </div>
      </div>

      <div class="ld_pricing-end-text">
        <p>*Taxes and Fees Extra</p>
      </div>

    </div>
  </section>
<!-- --------------pricing-section end------------- -->

<!-- --------------discount-section end------------- -->
  <section id="ld_discount-section" class="space">
    <div class="container">
      
      <div class="row">
        <div class="col-md-6 align-self-center">
          <div class="ld_discount-wrap">          
              <h4>Love Discounts?<br> Hate Delivery Fees?</h4>

              <h5>Pro(Crastinator) Plan</h5>
              <p>$9.99/Month for unlimited Free Delivery, 5% off, feeling of awesomeness that you are killing life and saving money.  Sign up when scheduling.</p>

              <h5>Free Loader</h5>
              <p>Save even more as a Free Loader.  Get a FREE Pro Plan membership and earn $20 Cash for referrals.  Want to learn more?</p>
      

          <div class="ld_Click">
              <a class="btn btn-primary dark-btn3" href="https://freeloder.laundryod.com/17243/freeloder" target="_blank">Click Here To Learn More <img src="assets/images/right-icon.png"></a>
          </div>r
          </div>
        </div>

        <div class="col-md-6">
            <div class="ld_discount-wrap2">
              <img src="assets/images/discount-img.webp" class="img-fluid" alt="">
            </div>
        </div>

      </div>

    </div>
  </section>
<!-- --------------discount-section end------------- -->


<!-- --------------video-section------------- -->
<section id="ld_video-section" class="space">
    <div class="container">
          <div class="pb-5"> <h4 class="text-center">How Big Is The Bag</h4> </div>
          <iframe
          width="300"
          height="655"
          src="assets/images/HomePageVideoImage.webp"
          srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/RVgujhOTC8o?autoplay=1><img src=assets/images/HomePageVideoImage.webp><span>▶</span></a>"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          title="Laundry On Demand How big is the bag"
        ></iframe>
        
         
                 </div>
</section>
<!-- --------------video-section end------------- -->

<!-- --------------about-section end------------- -->
<section id="ld_about-section" class="space">
  <div class="container added-space">
    <div class="row">
      <div class="col-md-5 align-self-center">
        <div class="ld_about-head">
        <h2>What<br> do people<br> say about us.</h2>
      </div>
      </div>

      <div class="col-md-7">
        
          <div class="row">
            <div class="col-md-3 align-self-center">
              <div class="ld_about-img">  
                  <img src="assets/images/testimonial-one.webp" class="img-fluid" alt="">
              </div>
            </div>
            <div class="col-md-9">
                <div class="ld_about-wrap">   
                  <h5>Voted best in big d</h5>
                  <p>by D Magazine</p>
                  <p class="bottom-txt">This is a huge honor!  Pinch us.  We still can't believe it.</p>
                </div>
            </div>
          </div>
              <div class="line">
              </div>

            <div class="row">
            <div class="col-md-3 align-self-center">
              <div class="ld_about-img2"> 
                  <img src="assets/images/testimonial-two.webp" class="img-fluid" alt="">
              </div>
            </div>
            <div class="col-md-9">
                <div class="ld_about-wrap">   
                  <h5>Great ExperiencE!</h5>
                  <p>Our dryer stopped working and we needed to get caught up on laundry while we waited to get it repaired. We had 4 kids home for the weekend and 2 of them sick. It wasn't possible to spend the day at the laundromat</p>
                  <p class="bottom-text">Erin-5 Stars Google</p>
                </div>
            </div>
          </div>
              <div class="line">
              </div>

              <div class="row">
            <div class="col-md-3 align-self-center">
              <div class="ld_about-img3"> 
                  <img src="assets/images/testimonial-three.webp" class="img-fluid" alt="">
              </div>
            </div>
            <div class="col-md-9">
                <div class="ld_about-wrap">   
                  <h5>THIS APP IS SO EASY! </h5>
                  <p>Makes life so much easier!  Clothes are washed, folded, and delivered the same day.  Reasonable priced and saves so much time.  I highly recommend!</p>
                  <p class="bottom-text">Sam-5 Stars Google</p>
                </div>
            </div>
          </div>
              

        </div>
    </div>
  </div>
</section>
<!-- --------------about-section end------------- -->


<!-- --------------do-section------------- -->

<section id="ld_do-section" class="space">
  <div class="container">
    <div class="ld_do-heading text-center">
      <h4>Let's Do This</h4>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="ld_text-wrap text-center">
          <img src="assets/images/schedule.webp" class="img-fluid" alt="">
          <div class="ld_text"> 
          <h6>Schedule</h6>
          <p>Schedule a service in the app or online and know exactly how much it will be BEFORE you place the order</p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="ld_text-wrap text-center">
          <img src="assets/images/set-out.webp" class="img-fluid" alt="">
          <div class="ld_text"> 
          <h6>Set Out</h6>
          <p>Set your laundry out in LOD bags or trash bags if its your first time.  You get real time updates on where your Laundry Pro is, so you can set it out just before they get there. </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="ld_text-wrap text-center">
          <img src="assets/images/rest.webp" class="img-fluid" alt="">
          <div class="ld_text"> 
          <h6>Relax</h6>
          <p>That's it. You are done. Your laundry will be delivered back the next day GUARANTEED! </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- --------------do-section end------------- -->


<!-- --------------pro-section------------- -->
<section id="ld_pro-section">
  <div class="container">
    <div class="ld_pro-heading">
        <h4>Preferred Pro</h4>
        <p>Have a Laundry Pro you love?  Who knows the way you love your shirts folded? <span class="pro-highlight">Keep them all to yourself.</span>  Select your preferred Pro in your dashboard and they will be the only one who gets your pick up.</p>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="ld_pro-img">
          <img src="assets/images/preffered.webp" class="img-fluid" alt="">
        </div>
      </div>
    </div>


  </div>
</section>
<!-- --------------pro-section end------------- -->


<!-- --------------service-section------------- -->
<section id="ld_service-section" class="space"> 
  <div class="container"> 
    <div class="row">
      <div class="col-md-6 order-md-2">
          <div class="ld_service-text-wrap" >
            <h4>Check Service Area</h4>
            <div class="form-group col-md-9 offset-md-3 align-self-end">
              <div class="centered input-search-wrap">
                      <input
                        type="text"
                        class="search-input"
                        placeholder="Enter Address..."
                        matGoogleMapsAutocomplete                     
                        [(ngModel)]="address"
                        
                      />
                      <button class="btn btn-success" (click)="checkServiceArea()"><img src="assets/images/white-arrow.png"></button>
                    </div>
            </div>
          </div>
        </div>
     <div class="col-md-6 "> 
      
      <div class="ld_service-wrap"> 
        <img src="assets/images/handgif.gif" class="img-fluid" alt=""> 
      </div> 
      
    </div>

        

      </div>
  </div>
</section>
<!-- --------------service-section end------------- -->


<!-- --------------faq-section------------- -->
<section id="ld_faq-section" class="space">
  <div class="container">
      <div class="ld_faq-heading  text-center">
        <h4>FAQ</h4>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="ld_faq_accord"> 
            
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingOne">
                      <h6 class="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        It’s my first time.  What should I expect? 
                      </a>
                    </h6>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                      <div class="panel-body">
                         We are SO excited to knock out that laundry. For your first time, put your clothes out in trash bags   (preferably 13-gallon kitchen bags). 1.5 trash bags for a small bag, and 3 trash bags for a large bag.  Then we will knock that laundry out and get it back to you same day or the next.  That’s it.  Fill a bag  schedule your pickup and set outside.  We take care of the rest. 
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingTwo">
                      <h6 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                       Service Area?
                      </a>
                    </h6>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                      <div class="panel-body">
                        We service all of DFW.  Check your address above to see if we are in your area yet. 
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                      <h6 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                       Can I request the same person each time to do my laundry?
                      </a>
                    </h6>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                      <div class="panel-body">
                        YES!  Find that perfect Laundry Pro and keep them all to yourself.
                      </div>
                    </div>
                  </div>

                    <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingfour">
                      <h6 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                       Do you offer dry cleaning?
                      </a>
                    </h6>
                    </div>
                    <div id="collapsefour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                      <div class="panel-body">
                        Not at this time. 
                      </div>
                    </div>
                  </div>

                </div>
            </div>
      </div>

        <div class="col-md-6">
          <div class="ld_faq_accord"> 
            
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingfive">
                      <h6 class="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                        Will you hang my clothes? 
                      </a>
                    </h6>
                    </div>
                    <div id="collapsefive" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingfive">
                      <div class="panel-body">
                         Certainly.  Send in hangers and we will take care of it for you.
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingsix">
                      <h6 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                       Can I send my own detergent? 
                      </a>
                    </h6>
                    </div>
                    <div id="collapsesix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                      <div class="panel-body">
                        Yes.  
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingseven">
                      <h6 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                       Do you have unscented detergent? 
                      </a>
                    </h6>
                    </div>
                    <div id="collapseseven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingseven">
                      <div class="panel-body">
                         No. If you would like unscented detergent, please send it in with your laundry.  
                      </div>
                    </div>
                  </div>

                    <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingeight">
                      <h6 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                       Are my clothes washed with any one else’s? 
                      </a>
                    </h6>
                    </div>
                    <div id="collapseeight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingeight">
                      <div class="panel-body">
                        Not at all!  Your clothes are never washed/dried/folded with anyone else’s clothes EVER. 
                      </div>
                    </div>
                  </div>

                </div>
            </div>
      </div>




    </div>
</div>
</section>

<!-- --------------faq-section end------------- -->

<!-- ------------laundry-section------------ -->


<section id="ld_laundry-section" class="space-pb">
    <div class="container">
      <div class="row">

        <div class="col-md-6">
          <img src="assets/images/mobile-pic.webp" class="img-fluid" alt="">
        </div>

        <div class="col-md-6 align-self-center">
          <div class="ld_laundry-wrap">
            <p>Download Our App</p>
            <h5>Do You Love Laundry</h5>
            <p>If so, head on over to the partners page and fill out the form to get the process started. You must be able to pass the background check and have reliable transporation.</p>
            <div class="ld_laundry-img">
              <a href="https://apps.apple.com/us/app/laundry-on-demand-partner/id1530503222" target="_blank"><img src="assets/images/apple-icon.png" class="img-fluid" alt=""></a>
              <a href="https://play.google.com/store/apps/details?id=com.redsLaundry.driver" target="_blank"><img src="assets/images/google-play-icon.png" class="img-fluid" alt=""></a>
            </div>
            <a class="btn btn-primary dark-btn3" href="https://partners.laundryod.com" target="_blank">Partner Sign Up Here</a>
          </div>
        </div>

      </div>
    </div>
</section>
<!-- ------------laundry-section end------------ -->

<!-- Modal -->
<ng-template #content let-modal>  
  <div class="modal-body p-0 m-0" style="overflow: hidden;">
    <div class="row popup-background-image row-with-divs">
      <!-- Half of the modal-body div-->
      <div class="d-none d-md-block d-lg-block d-xl-block d-md-6 p-0 w-50" style="cursor: pointer" onclick="location.href='https://thematinfo.laundryod.com/47274/thematinfo';">
        
      </div>
      <div class="d-none d-md-block d-lg-block d-xl-block d-md-6 p-0  w-50" style="cursor: pointer" (click)="modal.dismiss('Cross click')">
        
      </div>
      <div class="d-sm-block d-sm-12 d-md-none d-lg-none d-xl-none p-0 w-100" style="cursor: pointer" onclick="location.href='https://thematinfo.laundryod.com/47274/thematinfo';">
        
      </div>
      <div class="d-sm-block d-sm-12 d-md-none d-lg-none d-xl-none p-0  w-100" style="cursor: pointer" (click)="modal.dismiss('Cross click')">
        
      </div>
      <!-- Other half of the modal-body div-->
    </div>
  </div>  
</ng-template>