  <header id="header" class="app-header-navigation">
    <div class="banner" id="bannerTop">     
      <div class="row m-0">
         <div class="col-12">
            <div class="text-center my-auto top-header">
             <h6 class="top-header-text">Get $15 off your FIRST service!  Use code laundrysucks</h6>
            </div>   
         </div>         
      </div>             
    </div>
           
      </header> 
      <section id="ld_nav-section" class="ld-nav-section">
          <div class="container">
                <nav class="navbar navbar-expand-lg navbar-dark bg-light">
                <a class="navbar-brand" href="#"><img src="assets/images/laundry-logo.png" class="img-fluid lod-logo" alt=""></a>                
                <form class="form-inline my-2 my-lg-0 d-sm-block d-md-block d-lg-none d-xl-none">                   
                  <a class="btn btn-primary top-btn header-btn" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="schedule()">Schedule</a>
                </form>
                <a class="btn btn-primary top-btn-download d-sm-block d-md-block d-lg-none d-xl-none" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="downloadApps()">Download</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav ml-auto ">
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scroll('ld_pricing-section')">Price</a>
                    </li>
                    <li class="nav-item" >
                      <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scroll('ld_faq-section')">Faq</a>
                    </li>
                     <li class="nav-item">
                      <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="goToLink('blog')">Blog</a>
                    </li>
                    <li *ngIf="isLoggedIn()">                                 
                      <a  class="dropdown-item nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['/account']">
                        Dashboard
                      </a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link"
                        *ngIf="!isLoggedIn()"
                        class="nav-link auth-button"
                        data-toggle="collapse" data-target=".navbar-collapse.show"
                        (click)="openRegisterModal()"

                        >Sign Up</a>
                    </li>
                     <li class="nav-item" *ngIf="!isLoggedIn()">
                      <a class="nav-link"
                      
                      class="nav-link auth-button"
                      data-toggle="collapse" data-target=".navbar-collapse.show"
                      (click)="openLoginModal()"

                      >Sign In</a>
                    </li>
                     <li *ngIf="isLoggedIn()">
                      <a class="nav-link auth-button" (click)="logout()">
                       Logout
                      </a>
                    </li>
                    
                  </ul>
                  <form class="form-inline my-2 my-lg-0">
                   
                    <a class="btn btn-primary top-btn" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="schedule()">Schedule</a>
                  </form>
                </div>
              </nav>

            </div>  
        </section>

 

 
