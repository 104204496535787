import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManageScheduleComponent } from 'src/app/pages/schedules/manage-schedule/manage-schedule.component';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.css'],
})
export class SuccessMessageComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ManageScheduleComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
