import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubscribeComponent } from 'src/app/pages/subscribe/subscribe.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-subscribe',
  templateUrl: './success-subscribe.component.html',
  styleUrls: ['./success-subscribe.component.css']
})
export class SuccessSubscribeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SubscribeComponent>,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
  scheduleNow(){
      this.dialog.closeAll();
      this.router.navigate(['/order']);
  }
}
