import { Component, NgModule, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})

export class WalletComponent implements OnInit {
  spinner: boolean;
  p: number = 1;
  per_page:  number = 100;
  total: number = 0;
  balances:any;  
  constructor(
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getWalletHistory();
  }
  /**
   * Write code on Method
   *
   * @return response()
   */
   pageChangeEvent(event: number){
    this.p = event;
    this.getWalletHistory();
}
parseDate(input) {
  var date = new Date(input.replace(/\s+/g, 'T'));
  return ( date.toLocaleDateString());  
}
  getWalletHistory(){
    const body = {      
      customer_id: sessionStorage.getItem('user_id'),
      page: (this.p - 1)
      };
      this.spinner = true;
      this.userService.getWalletHistory(body).subscribe(
      (res: any) => {
        if(res.status){         
          this.balances = res.data;
          this.total = res.total;
          this.per_page = res.per_page;                      
        }        
        else{
          this.snackBar.open(res.message, 'Dismiss', {
            duration: 3000,
            panelClass: ['notif-failure'],
            verticalPosition: 'top',
          });
        }
      },
      (error: any) => {
        this.snackBar.open(error.message, 'Dismiss', {
          duration: 3000,
          panelClass: ['notif-failure'],
          verticalPosition: 'top',
        });
      }
    );
  }
}
