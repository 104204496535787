<h3>Your Schedule is almost ready!</h3>

<div *ngIf="this.toggle">
  <mat-form-field style="width: 100% !important">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="bookDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field style="width: 100% !important">
    <mat-label name="laundryNotes">Schedule time</mat-label>
    <input
      type="text"
      placeholder="Pick one"
      aria-label="Number"
      matInput
      [formControl]="bookTime"
      [matAutocomplete]="auto"
      name="bookTime"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<mat-form-field style="width: 100% !important">
  <mat-label name="laundryNotes">Notes</mat-label>
  <textarea type="text" matInput [(ngModel)]="notes"></textarea>
</mat-form-field>

<mat-form-field style="width: 100% !important">
  <mat-label>Number of small bags</mat-label>
  <input type="number" matInput [(ngModel)]="smallbagcount" /> 
</mat-form-field>

<mat-form-field style="width: 100% !important">
  <mat-label>Number of large bags</mat-label>
  <input type="number" matInput [(ngModel)]="largebagcount" />
</mat-form-field>

<div class="modal-button centered">
  <div *ngIf="!this.toggle">
    <button class="swal-button--cancel" (click)="toggleBookLater()">
      Book for Later
    </button>
    &nbsp;
    <button class="swal-button--success" (click)="bookNow()">Book Now!</button>
  </div>

  <div *ngIf="this.toggle">
    <button class="swal-button--cancel" (click)="toggleBookLater()">
      Return
    </button>
    &nbsp;
    <button class="swal-button--cancel" (click)="bookLater()">Confirm</button>
  </div>
</div>

<!-- Spinner -->
<div *ngIf="spinner" class="spinner-background">
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
