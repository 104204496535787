import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './core/guards/auth.guard';
import { ProfileComponent } from './pages/profile/profile.component';
import { SchedulesComponent } from './pages/schedules/schedules.component';

// New pages
import { PlansComponent } from './pages/plans/plans.component';
import { JobComponent } from './pages/job/job.component';
import { CardComponent } from './pages/card/card.component';
import { OrderComponent } from './pages/order/order.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { FriscoComponent } from './pages/frisco/frisco.component';
import { DallasComponent } from './pages/dallas/dallas.component';
import { LittleelmComponent } from './pages/littleelm/littleelm.component';
import { AubreyComponent } from './pages/aubrey/aubrey.component';
import { CrossroadsComponent } from './pages/crossroads/crossroads.component';
import { DentonComponent } from './pages/denton/denton.component';
import { ArgyleComponent } from './pages/argyle/argyle.component';
import { FlowermoundComponent } from './pages/flowermound/flowermound.component';
import { FortworthComponent } from './pages/fortworth/fortworth.component';



const routes: Routes = [
  { path: '', component: SchedulesComponent },
  { path: 'login', component: LoginComponent },
  {path: 'aubrey', component: AubreyComponent},
  {path: 'argyle', component: ArgyleComponent},
  {path: 'fortworth', component: FortworthComponent},
  {path: 'flowermound', component: FlowermoundComponent},
  {path: 'crossroads', component: CrossroadsComponent},
  {path: 'denton', component: DentonComponent},
  {path: 'frisco', component: FriscoComponent},
  {path: 'littleelm', component: LittleelmComponent},
  {path: 'dallas', component: DallasComponent},
  { path: 'job/:id', component: JobComponent },
  { path: 'order', component: OrderComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'card', component: CardComponent },
  { path: 'account', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'plans', component: PlansComponent, canActivate: [AuthGuard] },
  { path: 'wallet', component: WalletComponent },
  {
    path: 'schedules',
    component: SchedulesComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],  
})
export class AppRoutingModule {}
