<div class="page-content">
    <mat-card>      
        <form [formGroup]="resetPasswordForm">
            <div class="form-wrap">          
                <div class="form-inr">
                    <div class="row">
                        <div class="col-12">                
                            <div class="card">
                                <div class="card-header">
                                    Reset Password
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                        <div class="form-group">
                                            <label>Enter your Email Address</label>
                                            <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required />
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email address is required</div>
                                            </div>
                                        </div>
                                        </div>           
                                        <div class="col-12">
                                            <button mat-raised-button color="primary" (click)="resetPassword()">
                                                Reset Password
                                            </button>
                                        </div>                             
                                    </div>                                    
                                </div>
                                <div class="card-footer">                                                                        
                                    <p>Already registered? <a (click)="login()" style="color: #61aeef;">Sign In</a></p>
                                    <p>Don't have an account? <a (click)="signup()"  style="color: #61aeef;">Sign Up</a></p>
                                </div>
                            </div>   
                        </div>  
                    </div>
                </div>
            </div>
        </form>
    </mat-card>    
</div>